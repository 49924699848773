import {
  IBasicSelect,
  ISetFieldValue,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {ErrorMessage} from 'formik'
import {Dispatch, SetStateAction, useCallback} from 'react'
import {ICategorySelectOption} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {customStyles} from '../../form-elements/select/ReactSelectTemplate'

interface Props {
  setFieldValue: ISetFieldValue
  value: Partial<ICategorySelectOption>
  options: ICategorySelectOption[]
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
  onInputChange: (query: string, callback?: any) => void
}

export function SearchCategorySelect({
  setFieldValue,
  value,
  inputName,
  isLoading,
  noOptionsMessage = 'Не найдено',
  disabled = false,
  options,
  onInputChange,
}: Props) {
  const intl = useIntl()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage}</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = useCallback(
    (inputValue: string, callback: any) => {
      if (!inputValue) {
        callback(options)
        return
      }

      onInputChange(inputValue, callback)
    },
    [onInputChange, options]
  )

  const handleOnChange = (selectedValue: Partial<ICategorySelectOption> | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }
  return (
    <>
      <label className={`d-flex position-relative fs-6 flex-column mb-1 w-100`} htmlFor={inputName}>
        <span className={`required ms-5`}>
          <FormattedMessage id='COMPONENTS.SearchCategorySelect.CATEGORY' />
        </span>

        <AsyncSelect
          formatOptionLabel={({value, label, logoUrl}) => (
            <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
              <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
                <img
                  alt=''
                  className='rounded-circle w-35px me-2 fit-content'
                  src={
                    logoUrl
                      ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${logoUrl}`
                      : '/media/icons/duotune/ecommerce/ecm002.svg'
                  }
                />
              </div>

              <div className='d-flex flex-column text-gray-800'>
                <strong>{label}</strong>
                {/* {descr && <span className='fs-6 fw-normal'>{descr}</span>} */}
              </div>
            </div>
          )}
          styles={customStyles}
          components={{NoOptionsMessage, IndicatorSeparator: () => null}}
          classNames={{
            control: (state: any) => `h-50px fw-normal`,
          }}
          loadOptions={loadOptions}
          defaultOptions={options}
          form={inputName}
          isClearable={value.value === '' ? false : true}
          isDisabled={disabled}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          placeholder={intl.formatMessage({
            id: 'COMPONENTS.SearchCategorySelect.PLACEHOLDER',
          })}
          isLoading={isLoading}
          value={value}
          onInputChange={(e) => {
            onInputChange(e)
            // console.log('e', e)
          }}
          onChange={handleOnChange}
        />
      </label>
      <div className='text-danger'>
        <ErrorMessage name={`${inputName}.value`} />
      </div>
    </>
  )
}
