import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ITrackPackageItem, Track} from '../../core/_models/_tracks-models'
import {AllTrackParcelsRow} from './AllTrackParcelsRow'

interface Props {
  track: Track
}

export function AllTrackParcels({track}: Props) {
  const {track_package} = track
  const intl = useIntl()
  return (
    <div className='card pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <Statistics track_package={track_package} />
        <div className='separator my-5 border-gray-500'></div>

        <Table track={track} />
      </div>
    </div>
  )
}

type TStatProps = {
  track_package: ITrackPackageItem[]
}

const Statistics = ({track_package}: TStatProps) => {
  const intl = useIntl()
  const stats = [
    {
      icon: '/media/icons/duotune/general/gen017.svg',
      value: track_package.length,
      label: 'ITEMS',
    },
    {
      icon: '/media/icons/duotune/electronics/elc008.svg',
      value: `${track_package.reduce((acc, i) => acc + +i.weight, 0)} ${intl.formatMessage({
        id: 'GENERAL.MEASURES.KG',
      })}`,
      label: 'WEIGHT',
    },
    {
      icon: '/media/icons/duotune/ecommerce/ecm005.svg',
      value: track_package.reduce(
        (acc, i) => acc + +i.prods.reduce((accProd, prod) => accProd + +prod.qty, 0),
        0
      ),
      label: 'GOODS',
    },
    {
      icon: '/media/icons/duotune/ecommerce/ecm006.svg',
      value: `$${track_package.reduce((acc, i) => acc + +i.cost.delivery, 0)}`,
      label: 'DEL',
    },
    {
      icon: '/media/icons/customs.svg',
      value: `$${track_package.reduce((acc, i) => acc + +i.cost.mitn, 0)}`,
      label: 'CUSTOMS',
    },
  ]
  return (
    <div className='d-flex flex-column flex-grow-1 pe-8'>
      <div className='d-flex flex-wrap justify-content-around'>
        {stats.map(({icon, value, label}, index) => (
          <StatItem icon={icon} value={value} label={label} key={index} />
        ))}
      </div>
    </div>
  )
}

type TStatItemProps = {icon: string; value: string | number; label: string}

const StatItem = ({icon, value, label}: TStatItemProps) => {
  const intl = useIntl()
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip>{intl.formatMessage({id: `TRACK_PAGE.PARCELS_TAB.STAT.${label}`})} </Tooltip>
      }
    >
      <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6'>
        <div className='d-flex align-items-center'>
          <KTSVG path={icon} className='svg-icon-1 svg-icon-dark me-2' />
          <div className='fs-2 fw-bolder'>{value}</div>
        </div>
      </div>
    </OverlayTrigger>
  )
}

const Table = ({track}: Props) => {
  const {track_package} = track
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-75px '>
              <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.DATE_COLUMN' />
            </th>
            <th className='min-w-100px '>
              <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.PARCEL_COLUMN' />
            </th>
            <th className='min-w-100px '>
              <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.CONTENT_COLUMN' />
            </th>
            <th className='min-w-75px '>
              <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.WEIGHT_COLUMN' />
            </th>
            <th className='mw-100px'>
              <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.DEL_COST_COLUMN' />
            </th>
            <th className='min-w-100px '>
              <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.TRACKS_COLUMN' />
            </th>
          </tr>
        </thead>

        <tbody>
          {track_package.length > 0 ? (
            track_package?.map((item) => (
              <AllTrackParcelsRow track_package={item} key={item.code} />
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  <FormattedMessage id='PARCELS.LIST.NO_RESULTS' />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
