import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers, FormikErrors, useFormik, Field, FormikProps} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {
  AddTrackForm,
  IInvoice,
  IRecentInvoiceRes,
  ITrackFormProds,
  Track,
} from '../../core/_models/_tracks-models'
import {createTrackOnePageSchema} from '../../core/yup/track'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {CheckboxSwitcher} from '../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {Notification} from '../../../../modules/custom/Notification'
import {TrackGoods} from '../../modules/goods/TrackGoods'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {addTrack} from '../../core/_requests'
import {TrackCodeField} from '../../modules/general/TrackCodeField'
import {CreateLoading} from '../../../../modules/custom/responseHandlers/CreateLoading'
import {CreateError} from '../../../../modules/custom/responseHandlers/CreateError'
import {useAuth} from '../../../../modules/auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {useListView} from '../../core/ListViewProvider'
import {TrackInvoicesTab} from '../../modules/invoices/TrackInvoicesTab'
import {TrackInspectionTab} from '../../modules/inspection/TrackInspectionTab'
import {TrackParcelType} from '../../modules/parcel/TrackParcelType'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import {TrackReceiverInput} from '../../modules/TrackReceiverInput'
import {OnePageModalHeader} from './OnePageModalHeader'
import ScrollToFieldError from './ScrollToFieldError'
import {reformTrackFormProductsToApi} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {IFormProd} from '../../../buyouts/pokupka/core/_models'

interface Props {
  track?: Track
  goodsFields?: IFormProd[]
}

const CreateTrackOnePageForm = ({track, goodsFields}: Props) => {
  // service
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {itemIdForUpdate} = useListView()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()

  // form fields data
  const receiversListData: IBasicSelect[] = queryClient.getQueryData('receiversList') || []
  const recentInvoicesData: IRecentInvoiceRes[] = queryClient.getQueryData('recentInvoices') || []
  const [invoiceArray, setInvoiceArray] = useState<IInvoice[] | []>(
    track ? [...track.invoice_file] : []
  )

  const defFields = [
    {
      group: {label: '', value: ''},
      name: {label: '', value: '', require_invoice: 0, brands: []},
      brand: {label: '', value: ''},
      qty: 1,
      cost: '',
      total: '',
      repack: false,
      weight: '',
      used: false,
      user_name: '',
      model: '',
    },
  ]
  const mitn = track ? +track?.mitnitsya : 0

  const inits: AddTrackForm = {
    track_code: track ? track?.track_code : '',
    // delivery_type: track
    //   ? track?.type_delive
    //   : currentUser && currentUser.settings
    //   ? +currentUser?.settings?.delivery_type
    //   : 1,
    delivery_type: 1,
    package_type: track ? +track?.mitnitsya : 0,
    insurance: track?.insurance ? (track?.insurance === 1 ? true : false) : false,
    // @ts-ignore
    prods: goodsFields ? goodsFields : defFields,
    invoice_file: null,
    invoiceLinks: track?.invoice ? [...track?.invoice] : [''],
    orderInspection: track?.check_control ? (track?.check_control === 1 ? true : false) : false,
    inspectionDescription: track?.check_control_desc || '',
    receiverNeeded: true, //to-do
    receiver: track ? {label: '', value: track.receiver.id} : receiversListData[0],
    require_invoice: 0, //to-do
    earlySubmitAllowed: false, //to-do
    description: track?.description || '',
  }

  // useStates

  const [currDeliveryType, setCurrDeliveryType] = useState(inits.delivery_type)

  const [initValues] = useState<AddTrackForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  const [trackCode, setTrackCode] = useState(track ? track.track_code : '')

  const [trackCodeStatus, setTrackCodeStatus] = useState({success: false, msg: '', id: ''})

  useEffect(() => {
    inits.receiver = receiversListData[0]
  }, [receiversListData])

  //mutation
  const createTrackMutation = useMutation(addTrack, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      // setItemIdForUpdate(undefined)
      setInvoiceArray([])
      setTrackCode('')
      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (values: AddTrackForm, formikHelpers: FormikHelpers<AddTrackForm>) => {
    const {
      track_code,
      delivery_type,
      package_type,
      insurance,
      prods,
      invoiceLinks,
      orderInspection,
      inspectionDescription,
      receiver,
      description,
    } = values

    // const repackFootwear = prods.some(({repack}) => repack === true)

    const trackData = {
      track_code,
      type_delive: delivery_type,
      mitnitsya: package_type,
      insurance: insurance === true ? 1 : 0,

      prods: reformTrackFormProductsToApi(prods),
      invoice_file:
        invoiceArray.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
      invoice: invoiceLinks,
      check_control: orderInspection === true ? 1 : 0,
      check_control_desc: inspectionDescription,
      receiver: receiver?.value
        ? +receiver.value
        : receiversListData[0].value && +receiversListData[0].value,
      description: description ? description : undefined,
      // repack: repackFootwear === true ? 1 : 0,
    }

    console.log('trackDataToSend', trackData)

    // createTrackMutation.mutate(trackData)
    formikHelpers.resetForm()
    formikHelpers.setFieldValue('track_code', '')
    setTrackCodeStatus({success: false, msg: '', id: ''})
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={createTrackOnePageSchema(intl, +currDeliveryType === 0 ? 'avia' : 'sea')}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form' noValidate id='kt_modal_create_track_form'>
            {/* {JSON.stringify(errors)}*/}
            {/*                 {JSON.stringify(values)}
             */}
            <ScrollToFieldError />

            <OnePageModalHeader
              trackNumber={trackCode.toUpperCase() || ''}
              trackCodeStatus={trackCodeStatus}
              isLoading={isLoading}
              isValid={isValid}
            />
            <div
              className='scroll-y bg-white modal-body p-0'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <TrackCodeField
                      setFieldValue={setFieldValue}
                      value={values.track_code}
                      trackCodeStatus={trackCodeStatus}
                      setTrackCodeStatus={setTrackCodeStatus}
                      trackCode={trackCode}
                      disabled={!!itemIdForUpdate}
                      setTrackCode={setTrackCode}
                      twoFactorCheck={true}
                    />
                  </div>
                  <div className='fv-row mb-3'>
                    <CheckboxSwitcher
                      inputName='delivery_type'
                      firstOption={intl.formatMessage({
                        id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA',
                      })}
                      secondOption={intl.formatMessage({
                        id: 'GENERAL.DELIVERY_TYPE_NAME.SEA',
                      })}
                      value={+values.delivery_type}
                      setFieldValue={setFieldValue}
                      disabled={!!itemIdForUpdate || !trackCodeStatus.success}
                      handleChange={(val: number) => setCurrDeliveryType(val)}
                    />
                  </div>
                  {+values.delivery_type === 1 && (
                    <div className='fv-row mb-5'>
                      <Notification
                        noteText={
                          <FormattedMessage
                            id='GENERAL.NOTIFICATIONS.SEA_TRACK_BUYOUT'
                            values={{
                              // @ts-ignore
                              b: (chunks: any) => <b style={{color: 'black'}}>{chunks}</b>,
                              icon: <svg />,
                            }}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>{' '}
              <div className='separator bg-primary h-3px mb-3'></div>
              {(trackCodeStatus.success || track) && (
                <>
                  <div className='p-3 pb-6'>
                    <div className='w-100'>
                      <TrackGoods
                        prods={values.prods}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </div>
                  </div>
                  <div className='separator bg-primary h-3px my-3'></div>
                  <div className='p-3 pt-6'>
                    <TrackParcelType values={values} />
                  </div>
                  <div className='separator bg-primary h-3px my-3'></div>
                  <div className='p-3'>
                    <TrackInvoicesTab
                      invoiceArray={invoiceArray}
                      recentInvoicesData={recentInvoicesData}
                      setInvoiceArray={setInvoiceArray}
                      setIsLoading={setIsLoading}
                      values={values}
                    />
                  </div>
                  <div className='separator bg-primary h-3px mb-3'></div>
                  <div className='p-3'>
                    <TrackInspectionTab values={values} />{' '}
                  </div>
                  {/* {values.orderInspection && ( */}
                  <div className='separator bg-primary h-3px my-3'></div>
                  {/* )} */}
                  {receiversListData && (
                    <div className='p-3'>
                      <div className='fv-row mb-3'>
                        <InputTemplate
                          type='textarea'
                          inputName='description'
                          title={intl.formatMessage({
                            id: 'TRACKS.ADD_MODAL.FORM.COMMENT_TITLE',
                          })}
                          titleFontSize='fs-5'
                          required={false}
                          containerMarginBottom={false}
                          disabled={!!itemIdForUpdate}
                        />
                      </div>{' '}
                      <TrackReceiverInput values={values} receiversListData={receiversListData} />
                    </div>
                  )}
                </>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateTrackOnePageForm}
