import {Dispatch, SetStateAction} from 'react'
import {getBalanceShowMode, getSavedPagination} from './helpers'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  itemsPerPage: 10 | 30 | 50 | 100 | number
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: Record<string, any>
  final?: number
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  counters?: Record<string, number>
  currentPage?: number
  itemsPerPage?: number
  totalItems?: number
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  itemsPerPage: getSavedPagination(),
  order: 'desc',
  sort: 'id',
  final: 1,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID> | Array<any>
  onSelect: (selectedId: ID | any) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isArchive?: boolean
  setIsArchive?: Dispatch<SetStateAction<boolean>>
  isAllSelected: boolean
  disabled: boolean
  showSelectSummary?: boolean
  setShowSelectSummary?: Dispatch<SetStateAction<boolean>>
  showBalanceDetails?: boolean
  setShowBalanceDetails?: Dispatch<SetStateAction<boolean>>
  orderType?: 'buyout'
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  isArchive: false,
  setIsArchive: () => {},
  showSelectSummary: false,
  setShowSelectSummary: () => {},
  showBalanceDetails: JSON.parse(getBalanceShowMode()),
  setShowBalanceDetails: () => {},
}
