/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {IWarehouseData} from './core/_requests'
import {WarehouseItem} from './WarehouseItem'
import {WarehousesToolbarItem} from './WarehousesToolbarItem'

export function Warehouses() {
  const queryClient = useQueryClient()

  const whAddressesData: IWarehouseData[] =
    queryClient.getQueryData([QUERIES.WAREHOUSES_LIST]) || []

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [copied, setCopied] = useState<string[]>([])

  return (
    <div className='warehouses card mb-5 mb-xl-10 px-10 border-0'>
      <div className='card-header p-0 border-color-grey-light border-bottom-1 h-100px'>
        <div className='card-toolbar warehouses-toolbar rounded-custom-3 bg-custom-container px-4 my-7'>
          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 h-100'>
            {whAddressesData.map((item, index) => (
              <WarehousesToolbarItem
                key={`${item.id}-toolbar`}
                item={item}
                index={index}
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className='card-body'>
        <div className='tab-content' id='myTabContent'>
          {whAddressesData?.map((item, index) => (
            <WarehouseItem
              key={`${item.id}-card`}
              item={item}
              index={index}
              activeTabIndex={activeTabIndex}
              copied={copied}
              setCopied={setCopied}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
