import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../core/QueryResponseProvider'
import {buyoutsColumns} from './columns/_columns'
import {BuyoutsListLoading} from '../components/loading/BuyoutsListLoading'
import {BuyoutsListPagination} from '../components/pagination/BuyoutsListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {IOrder} from '../../core/_models'

const BuyoutsTable = () => {
  const buyouts = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => buyouts, [buyouts])
  const columns = useMemo(() => buyoutsColumns, [])
  const intl = useIntl()
  const {response: buyoutsTotal} = useQueryResponse()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='d-none d-md-block'>
        {buyoutsTotal?.totalItems !== undefined && buyoutsTotal?.itemsPerPage !== undefined && (
          <BuyoutsListPagination
            showPagination={buyoutsTotal?.totalItems > buyoutsTotal?.itemsPerPage}
            showItemsPerPage={buyoutsTotal?.totalItems <= 10 ? false : true}
          />
        )}
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<IOrder>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<IOrder>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='BUYOUTS.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {buyoutsTotal?.totalItems !== undefined && buyoutsTotal?.itemsPerPage !== undefined && (
        <BuyoutsListPagination
          showPagination={buyoutsTotal?.totalItems > buyoutsTotal?.itemsPerPage}
          showItemsPerPage={buyoutsTotal?.totalItems <= 10 ? false : true}
          addClass='mt-4 mt-md-0'
        />
      )}
      {isLoading && <BuyoutsListLoading />}
    </KTCardBody>
  )
}

export {BuyoutsTable}
