/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {useListView} from '../../../../core/ListViewProvider'
import {IOrder} from '../../../../core/_models'

type Props = {
  buyout: IOrder
}

const BuyoutNumberCell: FC<Props> = ({buyout}) => {
  const {id} = buyout
  const intl = useIntl()
  const {isArchive} = useListView()
  const pagePath = isArchive === true ? '/archived-buyouts/info' : '/buyouts/edit'

  const [show, setShow] = useState(false)
  const target = useRef(null)
  const buyoutNumber = useRef(null)

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm005.svg')} alt='' />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <div className='d-flex align-items-center'>
          <Link to={`${pagePath}/content/${id}`} className='d-flex m-2'>
            <span className='me-1 text-dark fw-bold fs-6'>
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3' />
            </span>
            <span className='text-dark fw-bold fs-6' ref={buyoutNumber}>
              {id}
            </span>
          </Link>
          <div
            ref={target}
            onClick={() => {
              copyToClipboard(buyoutNumber, setShow)
              setTimeout(() => {
                setShow(false)
              }, 1000)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${
                show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
              }`}
            />
          </div>{' '}
          <Overlay target={target.current} show={show} placement='right'>
            {(props) => (
              <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
            )}
          </Overlay>
        </div>
      </div>
    </div>
  )
}

export {BuyoutNumberCell}
