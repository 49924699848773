/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {Component, ErrorInfo, FC, ReactNode} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useParams, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {establishOwnerFromDomain} from '../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {RegistrationConfirmed} from '../modules/auth/components/RegistrationConfirmed'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

interface ErrorBoundaryProps {
  children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return {hasError: true, error}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Caught an error:', error)
    console.log('this', this.state.error?.name)
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return <Navigate to='/error/custom' state={{error: this.state.error}} />
    }

    return this.props.children
  }
}

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  let params = useParams()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <ErrorBoundary>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {currentUser ? (
              <>
                <Route path='auth/confirm/:token' element={<RegistrationConfirmed />} />
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/profile' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export {AppRoutes}
