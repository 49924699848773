import {IFormProd} from '../../../../app/pages/buyouts/pokupka/core/_models'
import {ITrackFormProds} from '../../../../app/pages/tracks/core/_models/_tracks-models'

export const checkForAnyCommercialProducts = (
  prods: ITrackFormProds[] | IFormProd[],
  delType: number
) => {
  //  brand
  // const isCommercialBrand = prods?.some(({brand}) => brand?.commercial && +brand?.commercial === 1)
  // qty
  // const isAnyCommQty = prods?.some(
  //   ({name, qty}) =>
  //     name?.settings &&
  //     +name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty &&
  //     +qty >= +name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty
  // )
  // const isCommercialQty = isAnyCommQty || isAnyTotalQuantityExceeded(prods, delType)
  // price
  // const isCommercialPrice = prods?.some(
  //   ({name, cost}) =>
  //     name?.settings &&
  //     +name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price &&
  //     +cost >= +name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price
  // )
  // let prodsAboveCap = false
  // if (delType === 1) {
  //   prodsAboveCap = anyProdsAboveCap(prods)
  // }
  // const anyComProds = isCommercialBrand || isCommercialQty || isCommercialPrice
  // return prodsAboveCap === true ? false : anyComProds
}

export const checkProductForCommercialValues = (
  prod: ITrackFormProds | IFormProd,
  delType: number
) => {
  // const isComProd =
  //   (prod?.brand?.commercial && +prod?.brand?.commercial === 1) ||
  //   (prod?.name?.settings &&
  //     +prod?.name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price &&
  //     +prod?.cost >= +prod?.name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price) ||
  //   (prod?.name?.settings &&
  //     +prod?.name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty &&
  //     +prod?.qty >= +prod?.name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty)
  // return isComProd
}

export const isAnyTotalQuantityExceeded = (
  prods: ITrackFormProds[] | IFormProd[],
  delType: number
) => {
  const productQuantities: Record<string, {total: number; com: number}> = {}

  // for (const prod of prods || []) {
  //   if (
  //     prod?.name?.settings &&
  //     +prod.name.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty &&
  //     prod?.name?.value
  //   ) {
  //     const productId = prod.name.value
  //     const comQty = +prod.name.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty
  //     const totalQty = (productQuantities[productId]?.total || 0) + +prod.qty

  //     productQuantities[productId] = {
  //       com: comQty,
  //       total: totalQty,
  //     }
  //   }
  // }

  // const result = productQuantities
  //   ? Object.entries(productQuantities).some(([key, {total, com}]) => total >= com)
  //   : false

  // return result
}

export const anyProdsAboveCap = (prods: ITrackFormProds[] | IFormProd[], delType: number = 1) => {
  // return prods.some((prod) =>
  //   prod.name.settings?.sea?.cap ? prod.cost > prod.name.settings?.sea?.cap : false
  // )
}
