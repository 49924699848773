/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction} from 'react'
import {useQueryClient} from 'react-query'
import {KTSVG, QUERIES} from '../../../_metronic/helpers'
import {IWarehouseData} from './core/_requests'
import flags from '../../../data/general/flags.json'

type TProps = {
  item: IWarehouseData
  index: number
  activeTabIndex: number
  setActiveTabIndex: Dispatch<SetStateAction<number>>
}

const WarehousesToolbarItem = ({item, index, activeTabIndex, setActiveTabIndex}: TProps) => {
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  return (
    <li className={`nav-item ${index === activeTabIndex ? 'active' : ''} px-7`}>
      <a
        className={`nav-link warehouses-link py-0 m-0 ${
          index === activeTabIndex ? 'active' : 'btn-color-muted'
        } border-bottom-0`}
        data-bs-toggle='tab'
        href='#'
        onClick={() => setActiveTabIndex(index)}
      >
        <KTSVG
          className={`svg-icon-3 me-5 icon country ${
            index === activeTabIndex ? '' : 'svg-icon-muted'
          }`}
          svgClassName='rounded-custom-3'
          path={
            flags.find(
              ({country}) => country.toLowerCase() === item.countryConfig.country.toLowerCase()
            )?.flag || ''
          }
        />
        {index === activeTabIndex && (
          <span className='text-dark'> {countries[item.countryConfig.country]}</span>
        )}
      </a>
    </li>
  )
}

export {WarehousesToolbarItem}
