import {Field, useFormikContext} from 'formik'
import InputMask from 'react-input-mask'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  inputName: string
  value: string | number
  numbersArray?: boolean
  index?: number
  handleChange?: (index: number, name: string, value: string) => void
}

export function PhoneNumberInput({
  inputName,
  value,
  numbersArray = false,
  index,
  handleChange,
}: Props) {
  const {setFieldValue} = useFormikContext()
  return (
    <Field
      name={inputName}
      render={() => (
        <InputMask
          name={inputName}
          type='tel'
          mask='(999) 999-99-99'
          className={`form-control form-control-lg text-dark fs-5 input_general input-border`}
          value={value}
          maskChar={null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // @ts-ignore

            numbersArray === true && index && handleChange
              ? handleChange(index, inputName, e.target.value)
              : setFieldValue(inputName, e.target.value)
          }}
        />
      )}
    />
  )
}
