import {ErrorMessage, Field, useFormikContext} from 'formik'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDebounce} from '../../../../../_metronic/helpers'
import {noOddSymbols} from '../../../../../_metronic/helpers/custom/regexs'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useAuth} from '../../../../modules/auth'
import {ITrackCodeStatus} from '../../core/_models/_tracks-models'
import {checkTrack, getTrackById} from '../../core/_requests'

interface Props {
  setFieldValue: ISetFieldValue
  value: string
  trackCode: string
  setTrackCode: Dispatch<SetStateAction<string>>
  trackCodeStatus: ITrackCodeStatus
  setTrackCodeStatus: (status: ITrackCodeStatus) => void
  withTitle?: boolean
  disabled?: boolean
  initialValue?: string
  twoFactorCheck?: boolean
}

function TrackCodeField({
  setFieldValue,
  value,
  trackCodeStatus,
  setTrackCodeStatus,
  withTitle = true,
  disabled = false,
  initialValue,
  trackCode,
  setTrackCode,
  twoFactorCheck = true,
}: Props) {
  const {currentUser} = useAuth()
  const debouncedTrack = useDebounce(trackCode, 1000)
  const {values} = useFormikContext()

  const getTheAddedTrack = async (id: string) => {
    const res = await getTrackById(id)
    console.log('r', res)
    // @ts-ignore
    const prev = {type_delive: values.delivery_type, description: values.description}

    if (res) {
      setFieldValue('delivery_type', res.type_delive)
      setFieldValue('description', res.description)
    } else {
      // setFieldValue('delivery_type', prev.type_delive || currentUser?.settings?.delivery_type)
      setFieldValue('description', prev.description)
    }
  }

  const handleChange = async (search: string, blur: boolean = false) => {
    const twoChannelsToCheck =
      twoFactorCheck === true ? search.length >= 7 || blur === true : blur === true
    if (twoChannelsToCheck) {
      const res = await checkTrack(search)

      setTrackCodeStatus(res)

      if (trackCodeStatus.id && trackCodeStatus.id !== '') {
        getTheAddedTrack(trackCodeStatus.id)
      }
    }
  }

  useEffect(() => {
    console.log(
      'CODE',
      debouncedTrack !== undefined,
      trackCode !== undefined,
      trackCode !== initialValue
    )

    if (debouncedTrack !== undefined && trackCode !== undefined && trackCode !== initialValue)
      handleChange(trackCode)
  }, [debouncedTrack])

  return (
    <div className='fv-row d-flex w-100 flex-column mb-5'>
      <label className='d-flex position-relative fs-5 flex-column fw-bold' htmlFor='track_code'>
        {withTitle === true && (
          <div className='mb-2'>
            <span className='fs-6 required'>
              <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE' />
            </span>
          </div>
        )}
        <Field
          type='text'
          disabled={disabled}
          className='form-control form-control-lg form-control-solid '
          name='track_code'
          value={trackCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const cleaned = e.target.value.trim().replace(noOddSymbols, '')
            setFieldValue('track_code', cleaned)
            setTrackCode(cleaned)
          }}
          onBlur={() => handleChange(trackCode, true)}
          autoFocus
        />
      </label>
      {disabled === false && (
        <>
          {trackCodeStatus.success === true && trackCodeStatus.msg && trackCode !== '' && (
            <div className='text-success'>
              <FormattedMessage id={trackCodeStatus.msg} />
            </div>
          )}
          {trackCodeStatus.success === false && trackCodeStatus.msg && trackCode !== '' && (
            <div className='text-danger'>
              {' '}
              <FormattedMessage id={trackCodeStatus.msg} />
            </div>
          )}
          <div className='text-danger'>
            <ErrorMessage name='track_code' />
          </div>
        </>
      )}
    </div>
  )
}

export {TrackCodeField}
