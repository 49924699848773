import {ReactNode} from 'react'
import {Modal} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {ID} from '../../../../_metronic/helpers'

interface Props {
  setModalOpen: (bool: boolean) => void
  id?: ID
  handleAction: (id?: ID) => void
  isOpen: boolean
  actionName?: string
  customText?: string
  warning?: string
}
export function ActionConfirmation({
  setModalOpen,
  id,
  handleAction,
  isOpen,
  actionName,
  customText,
  warning,
}: Props) {
  const intl = useIntl()
  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-300px'
      id='kt_modal_create_app'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered mw-300px'>
        <div className='modal-content  DeleteConfirmation__modal'>
          <div className='swal2-icon swal2-warning swal2-icon-show'>
            {' '}
            <div className='swal2-icon-content'>!</div>
          </div>

          <div className='mb-5'>
            <p className='fs-5 fw-bold DeleteConfirmation__confirm m-0'>
              {customText
                ? customText
                : `${intl.formatMessage({
                    id: 'COMPONENTS.ActionConfirmation.QUESTION_BASE',
                  })} ${actionName}?`}
            </p>
            {warning && <p className='fs-6 fw-bold text-danger mt-3'>{warning}</p>}
          </div>
          <div className='d-flex DeleteConfirmation__controllers'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-dark-primary me-2 min-w-100px'
              data-kt-menu-dismiss='true'
              onClick={() => setModalOpen(false)}
            >
              <FormattedMessage id='COMPONENTS.ActionConfirmation.CANCEL_BUTTON' />
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                id ? handleAction(id) : handleAction()
              }}
            >
              <FormattedMessage id='COMPONENTS.ActionConfirmation.CONF_BUTTON' />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
