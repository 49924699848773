import {useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  title: string
  value: string
  copied: string[]
  setCopied: (arr: string[]) => void
}

export function WarehouseLine({title, value, copied, setCopied}: Props) {
  const [isHovered, setIsHovered] = useState(false)

  const replacedTags = value?.replace(/<br\s?\/?>/g, ' ')

  return (
    <tr
      className='d-flex align-items-center warehouse-line'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td className='col warehouse-line-label-cont text-custom-grey'>
        <p className='m-0 warehouse-line-label' dangerouslySetInnerHTML={{__html: title + ':'}}></p>
      </td>

      <td className='col-lg-6 warehouse-line-value-cont ws-preline d-flex align-items-center'>
        <div>
          <span
            className='warehouse-line-value text-dark'
            dangerouslySetInnerHTML={{__html: value}}
          ></span>
        </div>

        <div className={`${isHovered === true ? 'd-block' : 'opacity-0'}`}>
          <CopyToClipboard
            text={replacedTags}
            onCopy={() => {
              setCopied([replacedTags])
            }}
          >
            <button type='button' className='highlight-copy btn'>
              <KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className={`svg-icon-3 ${
                  copied.includes(replacedTags) ? 'svg-icon-dark' : 'svg-icon-custom-grey'
                }`}
              />
            </button>
          </CopyToClipboard>
        </div>
      </td>
    </tr>
  )
}
