/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {statusColor} from '../../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {usePageData} from '../../../../../../../../_metronic/layout/core'
import {Portal} from '../../../../../../../../_metronic/partials'
import {useAuth} from '../../../../../../../modules/auth'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {IOrder} from '../../../../core/_models'

type Props = {
  buyout: IOrder
}

const BuyoutStatusCell = ({buyout}: Props) => {
  const {} = buyout
  const {refetch} = useQueryResponse()
  // const [show, setShow] = useState(false)

  const {currentUser} = useAuth()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const intl = useIntl()

  return (
    <div className='text-center'>
      {/* <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>{status}</p>{' '} */}
    </div>
  )
}

export {BuyoutStatusCell}
