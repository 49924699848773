import {FC, useEffect, useState} from 'react'
import {getConfig, useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/ru'
import '@formatjs/intl-relativetimeformat/locale-data/uk'
import ukMessages from './messages/uk.json'
import ruMessages from './messages/ru.json'
import {WithChildren} from '../helpers'
import {getTranslationData} from '../../app/modules/general/core/_requests'
import {establishOwnerFromDomain} from '../helpers/custom/funcs/establishOwnerFromDomain'

const allMessages = {
  uk: ukMessages,
  ru: ruMessages,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const [translationData, setTranslationData] = useState(null)
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  useEffect(() => {
    enableSplashScreen()
  }, [])

  const getTD = async () => {
    let owner = establishOwnerFromDomain()
    await getTranslationData(owner)
      .then(({data}) => {
        setTranslationData(data)
      })
      .catch((e) => {
        // @ts-ignore
        setTranslationData(allMessages[locale])
        console.log(e)
      })
  }

  useEffect(() => {
    getTD()
  }, [])

  return (
    <>
      {translationData && (
        <IntlProvider locale={locale} messages={translationData}>
          {children}
        </IntlProvider>
      )}
    </>
  )
}

export {I18nProvider}
