/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
} from '../../../../../_metronic/helpers'
import {BuyoutsTable} from './table/BuyoutsTable'
import {BuyoutsListHeader} from './components/header/BuyoutsListHeader'
import {useLocation} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {CreateBuyout} from '../_modals/CreateBuyout'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {useQuery} from 'react-query'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {directionsApi} from '../../../../../_metronic/helpers/custom/api/directionsApi'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {Direction} from '../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'

export const transformDirectionsForForm = (arr: Direction[], countries: Record<string, string>) => {
  return arr?.map((item) => {
    return {
      ...item,
      label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
      value: item.id,
    }
  })
}

const BuyoutsList = () => {
  const {itemIdForUpdate, setItemIdForUpdate, isArchive, setIsArchive} = useListView()
  const location = useLocation()
  const {pathname, state} = location
  const {data: countries} =
    useQuery(QUERIES.COUNTRY_ABBR_LIST, locationsApi.fetchDirectionCountriesList) || {}
  const {data: directions} =
    useQuery(QUERIES.DIRECTIONS_LIST, directionsApi.fetchDirectionsWithIds) || []
  console.log('c', countries)
  console.log('d', directions)

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })

    if (setIsArchive && pathname === '/archived-buyouts/list') {
      setIsArchive(true)
      updateState({
        ...initialQueryState,
        filter: {archive: 1},
      })
    }
  }, [])

  useEffect(() => {
    if (state === '/buyouts/create') {
      setItemIdForUpdate(null)
    }
  }, [state])

  return (
    <>
      <KTCard className='list-card'>
        <BuyoutsListHeader />

        {itemIdForUpdate !== undefined && directions && countries ? (
          <>
            <CreateBuyout directions={transformDirectionsForForm(directions, countries)} />

            <CardFooter
              success={false}
              addClass='shadow-pokupka justify-content-end px-8'
              staticPosition={true}
              toast={false}
            />
          </>
        ) : (
          <BuyoutsTable />
        )}
      </KTCard>
    </>
  )
}

const BuyoutsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BuyoutsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BuyoutsListWrapper}
