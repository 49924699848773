import {FieldArray} from 'formik'
import {GoodsItem} from './GoodsItem'
import {FormattedMessage} from 'react-intl'
import {useListView} from '../../../../pages/tracks/core/ListViewProvider'
import _ from 'lodash'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

import {ListViewContextProps} from '../../../../../_metronic/helpers'
import {IFormProd} from '../../../../pages/buyouts/pokupka/core/_models'

interface Props {
  prods: IFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: IFormProd[]}

  listView: ListViewContextProps
}

export function Goods({prods, setFieldValue, values, listView}: Props) {
  const {itemIdForUpdate} = listView

  return (
    <div className='d-flex flex-column'>
      <div className='w-100'>
        <FieldArray
          name='prods'
          render={(arrayHelpers) => (
            <div className='d-flex flex-column flex-col-gutter'>
              {prods.map((item, index) => (
                <GoodsItem
                  key={index}
                  index={index}
                  item={item}
                  arrayHelpers={arrayHelpers}
                  prods={prods}
                  setFieldValue={setFieldValue}
                  values={values}
                  listView={listView}
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  )
}
