import {establishOwnerFromDomain} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  console.log('removing auth')

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {
      headers: {
        Owner: string
        Authorization: string
      }
      url: string
    }) => {
      const auth = getAuth()

      const owner = establishOwnerFromDomain()

      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
        config.headers.Owner = String(owner)
      } else {
        config.headers.Owner = String(owner)
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => response,
    (err: any) => {
      const crauzer = process.env.REACT_APP_URL === 'crauzer.com'

      if (err.response && crauzer && err.response.status === 403) {
        removeAuth()
        console.log('logout AuthHelpers.tsx')

        window.location.href = '/logout'
      }
      return Promise.reject(err)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
