import React from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import {getWarehouseData} from './core/_requests'
import {Warehouses} from './Warehouses'

const WarehousesPage = () => {
  useQuery(QUERIES.COUNTRY_ABBR_LIST, locationsApi.fetchDirectionCountriesList)
  useQuery(QUERIES.WAREHOUSES_LIST, getWarehouseData)

  return <Warehouses />
}

export default WarehousesPage
