import flagsData from '../../../../data/general/flags.json'
import {Direction} from '../tsHelpers/directionsHelpers'

export type TParseDirections = (
  generalDirections: Direction[],
  websiteDirections: number[]
) => TDirectionArrayItem[]

export type TDirectionArrayItem = {
  from: string
  to: string
}
const formDirection = (from: string, to: string, list: Record<string, string>): string => {
  const getFrom = list[from]
  const getTo = list[to]

  return `${getFrom} - ${getTo}`
}

const findFlags = (countryFrom: string, countryTo: string) => {
  // console.log(countryFrom, countryTo)

  const findFromAbbr = flagsData.find((item) => item.country === countryFrom)
  const findToAbbr = flagsData.find((item) => item.country === countryTo)

  const obj = {from: findFromAbbr?.flag || '-', to: findToAbbr?.flag || '-'}
  return obj
}

const parseAvailableDirections: TParseDirections = (generalDirections, websiteDirections) => {
  const result = generalDirections
    .filter(function (d) {
      return websiteDirections.some(function (id) {
        return d.id === id
      })
    })
    .map(({countryFrom, countryTo}) => {
      return {from: countryFrom || '-', to: countryTo || '-'}
    })
  return result
}

export const directionsFuncs = {
  formDirection,
  parseAvailableDirections,
  findFlags,
}
