/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../core/Auth'
import {getUser, reg_confirm} from '../core/_requests'
import {crauzerProfileApi} from '../../../pages/profile/pokupka/core/_requests'

export function RegistrationConfirmed() {
  let params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  // @ts-ignore
  const telegram = location.state?.telegram

  const intl = useIntl()
  const {saveAuth, setCurrentUser} = useAuth()
  const [status, setStatus] = useState<string | boolean>(false)

  const authUser = async (token: string) => {
    try {
      let auth = {token: params?.token || ''}
      console.log('authUser token', token)

      if (!telegram) {
        console.log('authUser no tel')

        const {data} = await reg_confirm(token)
        auth = data
      }
      if (auth && typeof auth?.token === 'string') {
        saveAuth(auth)
      }
      const {data: user} = await getUser()
      const crUser = await crauzerProfileApi.getCurrentUser()
      setCurrentUser({...user, ...crUser})

      navigate('/profile')
    } catch (error: any) {
      console.error(error)
      let errMsg = intl.formatMessage({id: 'ERROR'})
      if (error.response.data?.message) {
        const val = error.response.data.message
        errMsg = intl.formatMessage({id: val, defaultMessage: val})
      }
      setStatus(errMsg)
    }
  }

  useEffect(() => {
    if (params.token) {
      console.log('ue params.token')

      authUser(params.token)
    } // eslint-disable-next-line
  }, [])

  return (
    <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <div className='mb-10 text-center'>
        <div className='text-gray-400 fw-bold fs-4'>
          {params.token && (
            <>
              <h1 className='text-dark mb-3'>
                <FormattedMessage id={`AUTH.${telegram ? 'NUMBER' : 'EMAIL'}_CONFIRMED`} />
              </h1>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
