/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {useListView} from '../../../../core/ListViewProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {IOrder} from '../../../../core/_models'
import {deleteBuyout} from '../../../../core/_requests'

type Props = {
  buyout: IOrder
}

const BuyoutActionsCell: FC<Props> = ({buyout}) => {
  const intl = useIntl()
  const {id} = buyout
  const {refetch} = useQueryResponse()
  const [modalOpen, setModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteBuyout(id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
  return (
    <div className='d-flex flex-gutter justify-content-center flex-shrink-0'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/buyouts/edit/content/${id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip>
            {intl.formatMessage({
              id: 'GENERAL.TOOLTIPS.TRASH',
            })}
          </Tooltip>
        }
      >
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => setModalOpen(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </OverlayTrigger>
      <button
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm show-chat'
        data-id={id}
        data-type='Buyout'
        data-name={id}
      >
        {/*<KTSVG path='/media/icons/duotune/communication/com003.svg' className='svg-icon-3' />*/}
      </button>
    </div>
  )
}

export {BuyoutActionsCell}
