import {useQueryRequest} from '../../../core/QueryRequestProvider'
import ListSearch from '../../../../../../modules/custom/lists/ListSearch'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const BuyoutsListHeader = () => {
  const {setItemIdForUpdate} = useListView()

  return (
    <div className='card-header border-0 pt-6 justify-content-end shadow-pokupka'>
      {/* <h3>
          <FormattedMessage
            id={isArchive === true ? 'BUYOUTS.PAGE.PAGE_HEADING_ARCH' : 'BUYOUTS.PAGE.PAGE_HEADING'}
          />
        </h3> */}

      <button
        type='button'
        className='btn btn-clean btn-shadow shadow-pokupka px-2 text-custom-accent fw-bold with-icon'
        onClick={() => setItemIdForUpdate(null)}
      >
        <KTSVG
          path='/media/company-specific/pokupka/cart.svg'
          className='svg-icon-4 icon svg-icon-custom-white d-flex align-items-center justify-content-center'
          svgClassName='svg'
        />
        <span className='px-4'>
          <FormattedMessage id='BUYOUTS.LIST.ADD_BUTTON' />
        </span>
      </button>

      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <BuyoutsListGrouping /> : <BuyoutsListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BuyoutsListHeader}
