import {FieldArray, useFormikContext} from 'formik'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ICrauzerProfileForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {useLayout} from '../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../../modules/auth'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ProfileNumberItem} from './ProfileNumberItem'

const ProfileContactNumbers = () => {
  const intl = useIntl()
  const {websiteData} = useLayout()
  const {currentUser} = useAuth()
  const {values} = useFormikContext<Partial<ICrauzerProfileForm>>()
  return (
    <>
      <FieldArray
        name='contactPhoneNumbers'
        render={() => (
          <div className='d-flex flex-column flex-col-gutter p-0'>
            {values.contactPhoneNumbers?.map((item, index) => (
              <InputTemplate
                title={
                  index === 0
                    ? intl.formatMessage({id: 'PROFILE.PAGE.CONTACTS.NUMBERS'})
                    : undefined
                }
                inputName={`contactPhoneNumbers.${index}`}
                type='tel'
                value={item}
                custom={
                  <div className='d-flex w-100 align-items-center'>
                    <ProfileNumberItem
                      user={currentUser}
                      inputName={`contactPhoneNumbers.${index}`}
                      value={item}
                    />
                    {!currentUser?.phoneNumber &&
                      currentUser?.phoneNumberConfirmed === false &&
                      currentUser.contactPhoneNumbers.find((i) => i === item) && (
                        <OverlayTrigger
                          placement='left'
                          overlay={<Tooltip>Підтвердити через Телеграм-бот.</Tooltip>}
                        >
                          <a
                            target={'_blank'}
                            href={`https://t.me/${websiteData.telegramBotName}`}
                            rel='noreferrer noopener'
                            className='btn btn-icon btn-light btn-lg ms-2'
                          >
                            <KTSVG
                              path='/media/svg/brand-logos/telegram-app.svg'
                              className='svg-icon-1'
                            />
                          </a>
                        </OverlayTrigger>
                      )}
                  </div>
                }
              />
            ))}
          </div>
        )}
      />
    </>
  )
}

export default ProfileContactNumbers
