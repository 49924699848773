import {Toast} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {Notification} from '../Notification'

interface Props {
  loading?: boolean
  buttonText?: string
  sendRequest?: (bool?: any) => any
  handleApi?: any
  apiData?: any
  disabled?: boolean
  success: boolean
  notification?: {
    success: boolean
    color: string
    intl: string
    icon: string
  } | null

  addClass?: string
  staticPosition?: boolean
  toast?: boolean
}
export function CardFooter({
  loading,
  disabled = false,
  buttonText,
  sendRequest,
  handleApi,
  apiData,
  success,
  notification,
  addClass,
  staticPosition = false,
  toast = true,
}: Props) {
  const intl = useIntl()
  const buttonTextFinal = buttonText ? buttonText : intl.formatMessage({id: 'GENERAL.BUTTONS.SAVE'})
  const disabledSubmit = loading === true || disabled === true ? true : false
  const performRequest = (e?: React.MouseEvent<HTMLElement>, preventDefault?: boolean) => {
    try {
      if (preventDefault === true && e) {
        e.preventDefault()
      }
      if (sendRequest) {
        sendRequest(true)
      } else if (handleApi) {
        handleApi(apiData)
      } else {
        console.log('empty click')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const submBtnClass = `rounded-circle btn text-white fw-normal fw-bold px-5 ms-2 btn-custom-text d-flex justify-content-center align-items-center ${
    staticPosition ? '' : 'position-absolute'
  } btn-normal`
  return (
    <div
      className={`card-footer d-flex align-items-center border-0 ${
        staticPosition
          ? 'position-relative'
          : 'position-absolute justify-content-center pt-4 px-0 pb-10 pb-md-0'
      } ${addClass}`}
    >
      {toast && (
        <Toast
          show={success}
          // onClose={() => handleClose(data.id)}
          delay={3000}
          autohide
          style={{zIndex: '0'}}
          className={`bg-light-${notification?.color} text-gray-800 w-100 h-100 top-0 start-0 border-0 position-absolute`}
        >
          <Toast.Body className={`bg-light-${notification?.color}`}>
            <Notification
              width='w-50'
              // noteText={intl.formatMessage({id: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING'})}
              // color={'success'}
              // icon='/media/icons/duotune/general/gen043.svg'
              noteText={intl.formatMessage({
                id: notification?.intl || 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
              })}
              color={notification?.color || 'success'}
              icon={notification?.icon || '/media/icons/duotune/general/gen043.svg'}
            />
            {/* <p className='m-0 p-0'></p> */}
          </Toast.Body>
        </Toast>
      )}

      <button
        type='submit'
        className={submBtnClass}
        disabled={disabledSubmit}
        style={{zIndex: '100'}}
        onClick={(e) => (handleApi ? performRequest(e, true) : performRequest())}
      >
        {!loading && buttonTextFinal}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {/* <FormattedMessage id='GENERAL.BUTTONS.WAIT' /> */}

            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  )
}
