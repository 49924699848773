import * as Yup from 'yup'
import {latinAndNumbersOnly} from '../../../../../../_metronic/helpers/custom/regexs'
import {object_shape_basic_select} from '../../../../../../_metronic/helpers/custom/yup/partials/generalPartials'

const trackCode = (intl: any) =>
  Yup.string()
    .matches(latinAndNumbersOnly, intl.messages.YUP_VALIDATION_GENERAL_LATIN_AND_NUMBERS_ONLY)
    .notOneOf([''], intl.messages.YUP_VALIDATION_ORDER_ENTER_ORDER_CODE)
    .required(intl.messages.YUP_VALIDATION_ORDER_ENTER_ORDER_CODE)
    .when('sendAsTrack', {
      is: true,
      then: Yup.string()
        .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_ENTER_TRACK_CODE)
        .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_TRACK_CODE)
        .label('Вага'),
    })

const receiverNeeded = Yup.boolean().required()

const receiver = (intl: any) =>
  object_shape_basic_select
    .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_REC)
    .label('Получатель')

const deliveryType = Yup.number().required().label('Тип доставки')
const description = Yup.string().label('Коментар')

const returnToStore = Yup.boolean().label('Повернути в магазин')

const prodsItem = (
  intl: any,
  checkCategory?: boolean,
  checkAmount?: boolean,
  urlIsNeeded?: boolean
) => {
  // console.log('urlIsNeeded', urlIsNeeded)

  const numericTransform = (value: any, originalValue: any) => {
    if (originalValue === '') {
      return 0
    }
    return value
  }

  return Yup.object({
    url: Yup.string().when([], {
      is: () => urlIsNeeded === true,
      then: Yup.string()
        .url(intl.messages.YUP_VALIDATION_URL)
        .required(intl.messages.YUP_VALIDATION_ENTER_LINK_TO_GOODS)
        .notOneOf([''], intl.messages.YUP_VALIDATION_ENTER_LINK_TO_GOODS),
    }),
    category: Yup.object()
      .when([], {
        is: () => checkCategory === true,
        then: Yup.object()
          .shape({
            label: Yup.string().when([], {
              is: () => checkCategory === true,
              then: Yup.string()
                .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
                .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
            }),
            value: Yup.string().when([], {
              is: () => checkCategory === true,
              then: Yup.string()
                .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
                .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
            }),
          })
          .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
      })
      .label('Категория товара'),

    qty: Yup.number()
      .when([], {
        is: () => checkAmount === true,
        then: Yup.number()
          .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_QTY)
          .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_ONE_GOODS_ITEM),
      })
      .label('Количество позиций'),

    cost: Yup.number()
      .transform(numericTransform)
      .when([], {
        is: () => checkCategory === true,
        then: Yup.number()
          .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_PRICE)
          .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG),
      })
      .label('Стоимость одной позиции'),

    total: Yup.number()
      .transform(numericTransform)
      .when([], {
        is: () => {
          return checkCategory === true
        },
        then: Yup.number()
          .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_TOTAL)
          .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG),
      })
      .label('Суммарная стоимость'),
  })
}

function prods(intl: any) {
  return Yup.array()
    .of(prodsItem(intl, true, true))
    .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
    .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
  // return Yup.array()
  //   .of(prodsItem(intl, false, false))
  //   .label('Товары')
  // .when(['location'], {
  //   is: (location: IWarehouse) =>
  //     // check category
  //     location?.acceptType === 'full',
  //   then: Yup.array()
  //     .of(prodsItem(intl, true, true))
  //     .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
  //     .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS),
  // })
  // .when(['location'], {
  //   is: (location: IWarehouse) =>
  //     // check amount
  //     location?.acceptType === 'amount',
  //   then: Yup.array()
  //     .of(prodsItem(intl, false, true))
  //     .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
  //     .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS),
  // })
  // .when(['location', 'sendAsBuyout'], {
  //   is: (location: IWarehouse, sendAsBuyout: boolean) =>
  //     // check for obligatory url for buyout

  //     {
  //       // console.log('buy', !location?.acceptType && sendAsBuyout)
  //       return !location?.acceptType && sendAsBuyout === true
  //     },

  //   then: Yup.array()
  //     .of(prodsItem(intl, true, true, true))
  //     .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
  //     .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS),
  //   // otherwise: Yup.array()
  //   //   .of(prodsItem(intl, true, true, false))
  //   //   .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
  //   //   .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS),
  // })
  // .when(['location', 'sendAsBuyout'], {
  //   is: (location: IWarehouse, sendAsBuyout: boolean) => !location?.acceptType && !sendAsBuyout,
  //   then: Yup.array()
  //     .of(prodsItem(intl, true, true, false))
  //     .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
  //     .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS),
  // })
}

const packageType = Yup.number().required().label('Тип посылки')
const insurance = Yup.boolean().label('Страховка')

const requireInvoice = Yup.number()
const invoiceFile = (intl: any) =>
  Yup.object()
    .nullable()
    .when(['require_invoice', 'invoiceLinks'], {
      is: (requireInvoice: number, invoiceLinks: string[]) =>
        requireInvoice === 1 && (!invoiceLinks || invoiceLinks.length === 0),
      then: Yup.object()
        .required(intl.messages.YUP_VALIDATION_TRACK_UPLOAD_INV)
        .shape({
          name: Yup.string(),
          file: Yup.object().shape({
            base_url: Yup.string(),
            filename: Yup.string(),
          }),
        }),
    })

// const invoiceLinks = (intl: any) =>
//   Yup.array()
//     .of(Yup.string().url(intl.messages.YUP_VALIDATION_URL))
//     .when(['require_invoice', 'invoice_file'], {
//       is: (requireInvoice: number, invoiceFile: IInvoice | null) =>
//         requireInvoice === 1 && !invoiceFile,
//       then: Yup.array().of(
//         Yup.string().when('length', {
//           is: 1,
//           then: Yup.string().required(intl.messages.YUP_VALIDATION_TRACK_UPLOAD_INV).notOneOf(['']),
//           otherwise: Yup.string().url(intl.messages.YUP_VALIDATION_URL),
//         })
//       ),
//     })
const orderInspection = Yup.boolean().label('Проверка трека')
const inspectionDescription = (intl: any) =>
  Yup.string().when('orderInspection', {
    is: true,
    then: Yup.string().required(intl.messages.YUP_VALIDATION_TRACK_ENTER_INSPECTION_DESC),
  })

export const orderPartials = {
  trackCode,
  receiverNeeded,
  receiver,
  deliveryType,
  description,
  returnToStore,
  prods,
  prodsItem,
  packageType,
  insurance,
  requireInvoice,
  // invoiceFile,
  // invoiceLinks,
  orderInspection,
  inspectionDescription,
}
