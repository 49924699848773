import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {setYupLocale} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {crauzerUpdatePassword} from '../core/_requests'
import {passwordRegex} from '../../../../_metronic/helpers/custom/regexs'
import clsx from 'clsx'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const ResetPassword = () => {
  let navigate = useNavigate()
  const intl = useIntl()
  let {token: paramsToken} = useParams()

  setYupLocale(intl)

  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()

  const passwordRecoverySchema = Yup.object().shape(
    {
      password: Yup.string()
        .min(8)
        .max(20)
        .matches(passwordRegex, intl.formatMessage({id: 'VALIDATION.INVALID'}))
        .required(),
      confirmPassword: Yup.string()
        .required()
        .when('password', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            intl.formatMessage({id: 'VALIDATION.PASSWORDS'})
          ),
        }),
    },
    // @ts-ignore
    ['password', 'confirmPassword']
  )

  const formik = useFormik({
    initialValues,
    validationSchema: passwordRecoverySchema,
    onSubmit: async (values, {setStatus, setSubmitting, setFieldError}) => {
      setLoading(true)
      setStatus(null)
      try {
        if (paramsToken) {
          await crauzerUpdatePassword(values.password, paramsToken)

          return navigate('/auth/login')
        }
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        let errMsg = intl.formatMessage({id: 'ERROR'})
        let ecount = 0
        if (error.response.data?.errors) {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
            if (key in values) {
              // @ts-ignore
              setFieldError(key, intl.formatMessage({id: value, defaultMessage: value}))
              ecount++
            } else {
              if (value && ecount === 0)
                // @ts-ignore
                errMsg = intl.formatMessage({id: value, defaultMessage: value})
            }
          }
        }
        if (ecount === 0) setStatus(errMsg)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.FORGOT.TITLE' />
        </h1>

        <div className='text-gray-400 fw-bold fs-4'>
          <Link to='/auth/login' className='link-primary fw-bolder'>
            <FormattedMessage id='AUTH.LOGIN.BUTTON' />
          </Link>
        </div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='mb-7 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6 required'>
            <FormattedMessage id='AUTH.INPUT.PASSWORD' /> <span className='text-danger'></span>
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              autoComplete='new-password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          <FormattedMessage
            id='AUTH.INPUT.PASSWORD_COND'
            values={
              // @ts-ignore
              {b: (chunks: any) => <b>{chunks}</b>}
            }
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 required'>
          <FormattedMessage id='AUTH.INPUT.CONFIRM_PASSWORD' />{' '}
          <span className='text-danger'></span>
        </label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='AUTH.GENERAL.SUBMIT_BUTTON' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='AUTH.GENERAL.WAIT' />{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            <FormattedMessage id='AUTH.GENERAL.CANCEL_BUTTON' />
          </button>
        </Link>
      </div>
    </form>
  )
}

export default ResetPassword
