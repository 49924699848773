import {Notification} from '../../Notification'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ModalNotification} from '../../modals/ModalNotification'
import {FormattedMessage} from 'react-intl'
import {ErrorMessage} from 'formik'
import {TUploadedFile} from '../../../../../_metronic/helpers/custom/tsHelpers/fileHelpers'
import ImgDocPreview from './ImgDocPreview'

interface Props {
  inputName: string
  title?: string
  setFieldValue: ISetFieldValue
  inputId: string
  loading: boolean
  linkEndpoint?: string // e.g. '/passport'
  uploadedFile: TUploadedFile
  uploadFunction?: (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: ISetFieldValue) => void
  simpleDeleteFunction?: () => void
  deleteFunction?: (setFieldValue: ISetFieldValue) => void
  placeholder?: string
  notification?: {bool: boolean; text?: string; borderTop?: boolean}
  required?: boolean
  formikHook?: boolean
}

export function FileInput({
  title,
  inputName,
  inputId,
  uploadedFile,
  loading,
  linkEndpoint = '',
  setFieldValue,
  uploadFunction = () => {
    return
  },
  deleteFunction,
  simpleDeleteFunction,
  placeholder,
  notification = {bool: false},
  required = false,
  formikHook = true,
}: Props) {
  return (
    <div className='row mb-2 w-100 d-flex justify-content-center'>
      {title && <label className={`col-lg-4 fs-5`}>{title} </label>}
      <div className='col-lg-8'>
        <div className='row d-flex align-items-center justify-content-around'>
          <div className='col-lg-6 fv-row mb-6'>
            {' '}
            <input
              name={inputName}
              accept='.jpg, .png, .pdf, .jpeg'
              className='form-control form-control-lg FileInput'
              type='file'
              placeholder={placeholder ? placeholder : ''}
              id={inputId}
              style={{display: 'none'}}
              onChange={(e) => uploadFunction(e, setFieldValue)}
            />
            <label className='btn btn-lg btn-secondary btn-active-primary' htmlFor={inputId}>
              {uploadedFile && uploadedFile.link !== '' ? (
                <FormattedMessage id='GENERAL.BUTTONS.UPDATE_FILE' />
              ) : (
                <FormattedMessage id='GENERAL.BUTTONS.CHOOSE_FILE' />
              )}
            </label>
            {formikHook && (
              <div className='text-danger'>
                <ErrorMessage name={inputName} />
              </div>
            )}
          </div>

          {/* ProfileOverview__passport-image class for div below in case mockup design is needed */}
          <div className='col-lg-6 fv-row mb-6 position-relative'>
            <label htmlFor={!uploadedFile || uploadedFile.link === '' ? inputId : ''}>
              {uploadedFile && (
                <ImgDocPreview
                  linkEndpoint={linkEndpoint}
                  loading={loading}
                  title={title || ''}
                  uploadedFile={uploadedFile}
                />
              )}

              {uploadedFile && uploadedFile.link !== '' && (
                <button
                  type='button'
                  className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm ProfileOverview__delete-image'
                  onClick={() => {
                    if (simpleDeleteFunction) {
                      simpleDeleteFunction()
                    } else if (deleteFunction) {
                      deleteFunction(setFieldValue)
                    } else {
                      return
                    }
                  }}
                >
                  <ModalNotification
                    svg='/media/icons/duotune/general/gen027.svg'
                    addSvgClass='svg-icon-3'
                    addClass='ModalNotification__button-position'
                    children={
                      <div className='fs-7 fw-normal text-black'>
                        <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
                      </div>
                    }
                  />
                </button>
              )}
            </label>
          </div>
        </div>{' '}
      </div>{' '}
      {notification.bool === true && (
        <Notification noteText={notification.text || ''} borderTop={notification.borderTop} />
      )}
    </div>
  )
}
