/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {confirmEmailResend, crauzerRegister, getUser} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {setYupLocale} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {FormattedMessage, useIntl} from 'react-intl'

import {emailRegex, passwordRegex, phoneRegex} from '../../../../_metronic/helpers/custom/regexs'
import {basicNameSchema} from '../../../../_metronic/helpers/custom/yup/partials/generalPartials'
import InputMask from 'react-input-mask'
import {Languages} from './Languages'
import {json} from 'stream/consumers'
import {TermsOfServiceModal} from '../../../pages/profile/pokupka/components/TermsOfServiceModal'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  changepassword: '',
  acceptTerms: true,
}

export function Registration() {
  const intl = useIntl()
  let navigate = useNavigate()
  setYupLocale(intl)
  const registrationSchema = Yup.object().shape(
    {
      email: Yup.string().required().email().min(5).max(50).matches(emailRegex),
      phoneNumber: Yup.string().required().min(10).max(19).matches(phoneRegex),
      password: Yup.string()
        .min(8)
        .max(20)
        .matches(passwordRegex, intl.formatMessage({id: 'VALIDATION.INVALID'}))
        .required(),
      changepassword: Yup.string()
        .required()
        .when('password', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            intl.formatMessage({id: 'VALIDATION.PASSWORDS'})
          ),
        }),
      firstName: basicNameSchema(
        intl,
        intl.formatMessage({id: 'YUP_VALIDATION_GENERAL_ENTER_NAME'})
      ),
      lastName: basicNameSchema(
        intl,
        intl.formatMessage({id: 'YUP_VALIDATION_GENERAL_ENTER_LAST_NAME'})
      ),
      acceptTerms: Yup.bool().required(intl.formatMessage({id: 'VALIDATION.TERMS'})),
    },
    // @ts-ignore
    ['email', 'phoneNumber', 'password', 'changepassword', 'firstName', 'lastName']
  )
  const [loading, setLoading] = useState(false)
  const [tosOpen, setTosOpen] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const resendEmail = async (email: string) => {
    try {
      await confirmEmailResend(email)
      return navigate('/auth/registration/done', {state: {email}})
    } catch (error) {
      console.log('resend err', error)
    }
  }

  const try_token_auth = async () => {
    const {data: user} = await getUser()
    setCurrentUser(user)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setFieldError, setFieldValue}) => {
      setLoading(true)
      setStatus(null)
      try {
        let token = ''

        const {data: auth} = await crauzerRegister(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.phoneNumber,
          intl.locale
        )

        token = auth.token

        if (token) {
          saveAuth({token: token})
          try_token_auth()
        }
        setShowConfirm(true)
        setFieldValue('confToken', auth.data.telegram_check_token)
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        let errMsg = intl.formatMessage({id: 'ERROR'})
        let ecount = 0
        if (error.response.data?.errors) {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
            // @ts-ignore
            if (value[0] === 'AUTH.REGISTRATION.EMAIL_CONFIRMATION_REQUIRED') {
              setStatus({show: false, text: 'show-resend'})
            }
            console.log('val', value)

            if (key in values) {
              // @ts-ignore
              setFieldError(key, intl.formatMessage({id: value, defaultMessage: value}))
              ecount++
            } else {
              if (value && ecount === 0)
                // @ts-ignore
                errMsg = intl.formatMessage({id: value, defaultMessage: value})
            }
          }
        }
        if (ecount === 0) setStatus(errMsg)
        setSubmitting(false)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <TermsOfServiceModal
        registerProps={{isOpen: tosOpen, setIsOpen: setTosOpen, publicApi: true}}
      />
      {/* {JSON.stringify(formik?.errors)} */}
      {formik.status && formik.status?.show !== false && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label text-muted fw-normal fs-7 mb-0 ms-4'>
          Email <span className='text-danger'></span>
        </label>
        <input
          type='email'
          autoComplete='off'
          // placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
            'input_general input-border'
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
            {formik.status?.text && formik.status?.text === 'show-resend' && (
              <div className='d-flex justify-content-center mt-2'>
                <a
                  className='btn text-primary flex-center btn-light btn-secondary btn-sm'
                  href='#'
                  onClick={() => resendEmail(formik.values.email)}
                >
                  {intl.formatMessage({id: 'AUTH.EMAIL_RESEND'})}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label text-muted fw-normal fs-7 mb-0 ms-4'>
          <FormattedMessage id='AUTH.INPUT.PHONE' /> <span className='text-danger'></span>
        </label>

        <InputMask
          name='phoneNumber'
          type='tel'
          mask='+38 (099) 999 99 99'
          className={`form-control form-control-lg form-control-solid input_general input-border`}
          value={formik.values.phoneNumber}
          maskChar={null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.setFieldValue('phoneNumber', e.target.value)
          }}
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phoneNumber}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-4 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label text-muted fw-normal fs-7 mb-0 ms-4'>
            <FormattedMessage id='AUTH.INPUT.PASSWORD' /> <span className='text-danger'></span>
          </label>
          <div className='position-relative mb-4'>
            <input
              type='password'
              // placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              autoComplete='new-password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                },
                'input_general input-border'
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-4'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          <FormattedMessage
            id='AUTH.INPUT.PASSWORD_COND'
            values={
              // @ts-ignore
              {b: (chunks: any) => <b>{chunks}</b>}
            }
          />
        </div>
      </div>

      {/* end::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label text-muted fw-normal fs-7 mb-0 ms-4'>
          <FormattedMessage id='AUTH.INPUT.CONFIRM_PASSWORD' />{' '}
          <span className='text-danger'></span>
        </label>
        <input
          type='password'
          // placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            },
            'input_general input-border'
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-2'>
        <div className='fv-row mb-4'>
          <label className='form-label text-muted fw-normal fs-7 mb-0 ms-4'>
            <FormattedMessage id='AUTH.INPUT.FIRSTNAME' />
          </label>
          <input
            // placeholder={intl.formatMessage({id: 'AUTH.INPUT.FIRSTNAME'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              },
              'input_general input-border'
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        {/* begin::Form group Lastname */}
        <div className='fv-row'>
          <label className='form-label text-muted fw-normal fs-7 mb-0 ms-4'>
            <FormattedMessage id='AUTH.INPUT.LASTNAME' />
          </label>
          <input
            // placeholder={intl.formatMessage({id: 'AUTH.INPUT.LASTNAME'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.lastName && formik.errors.lastName,
              },
              {
                'is-valid': formik.touched.lastName && !formik.errors.lastName,
              },
              'input_general input-border'
            )}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastName}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-2'>
        <div className='form-check form-check-custom form-check-solid'>
          <label
            className='form-check-label fw-normal text-muted fs-7'
            htmlFor='kt_login_toc_agree'
          >
            <FormattedMessage id='AUTH.INPUT.ACCEPT' />{' '}
            <button
              type='button'
              className='ms-1 btn btn-link link-primary text-decoration-underline fs-7 text-blue fw-normal'
              onClick={() => setTosOpen(true)}
            >
              <FormattedMessage id='AUTH.INPUT.TERMS' />
            </button>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5 Login_form-button justify-content-center fs-2 py-8'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='AUTH.REGISTER.CREATE' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='AUTH.GENERAL.WAIT' />{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='d-flex justify-content-between align-items-center'>
          <Languages />
          <div className='d-flex flex-column flex-md-row'>
            <span className='text-muted me-2'> У вас вже є акаунт? </span>
            <Link
              to='/auth/login'
              className='link-primary fs-7 fw-normal text-blue text-decoration-underline'
            >
              <span> Вхід</span>
            </Link>
          </div>
        </div>
        {/* <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            <FormattedMessage id='AUTH.GENERAL.CANCEL_BUTTON' />
          </button>
        </Link> */}
      </div>
      {/* end::Form group */}
    </form>
  )
}
