import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {useIntl} from 'react-intl'
import {crauzerProfileApi} from './core/_requests'
import PokupkaPageTitle from '../../../modules/custom/pokupka/PokupkaPageTitle'
import PageForm from './PageForm'

const ProfilePage = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      {currentUser && (
        <>
          <PokupkaPageTitle id='PROFILE.PAGE.PAGE_TITLE' />
          <PageForm user={currentUser} setCurrentUser={setCurrentUser} />
        </>
      )}
    </>
  )
}

export default ProfilePage
