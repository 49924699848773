/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Portal} from '../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../modules/custom/modals/ActionConfirmation'
import {ITrackProds, Track} from '../../core/_models/_tracks-models'
import {editTrack, getTrackBrandsByCat} from '../../core/_requests'
import {EditTrackContentCard} from './_modals/EditTrackContentCard'
import {FormattedMessage, useIntl} from 'react-intl'

type Props = {
  items: ITrackProds[]
  item: ITrackProds
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
  setDisabledRadios: Dispatch<SetStateAction<boolean | undefined>>
}

export function TrackItemCard({
  items,
  item,
  track,
  setCurrTrack,
  isArchive,
  setDisabledRadios,
}: Props) {
  const intl = useIntl()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const {track_package} = track
  const {category, qty, cost, wgt, total, model, user_name, brand, used, name, commercial} = item
  // const {data: categoryBrands} = useQuery('catBrands', () => {
  //   return getTrackBrandsByCat(+category?.id)
  // })
  // console.log('editbrands', categoryBrands)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      const filteredProducts = track.prods.filter((prod) => prod.id !== item.id)
      return await editTrack({
        ...track,
        receiver: track?.receiver?.id ? +track?.receiver?.id : null,
        // @ts-ignore
        prods: filteredProducts,
        invoice_file:
          track.invoice_file.map((item) => {
            return {name: item.name, tmp_name: item.file.filename}
          }) || undefined,
        mitnitsya:
          +track?.type_delive === 0 &&
          track.prods
            .filter((prod) => prod.id !== item.id)
            .some((prod) => +prod.commercial === 1) === true
            ? 1
            : 0,
      })
    },
    {
      onSuccess: (data) => {
        setCurrTrack(data)
      },
      onSettled: () => {
        setDeleteModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <>
      {/* {editModalOpen && categoryBrands && ( */}
      {/* <Portal
          children={
            <EditTrackContentCard
              setIsOpen={setEditModalOpen}
              isOpen={editModalOpen}
              dataForEditing={item}
              track={track}
              readOnly={isArchive || track_package.length > 0}
              setCurrTrack={setCurrTrack}
              categoryBrands={categoryBrands}
              setDisabledRadios={setDisabledRadios}
            />
          }
          className='modal-root'
        />
      )} */}

      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB.DELETE_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={track.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <div className='col-sm-6 col-xl-4'>
        <div className='card h-100 border border-2 border-gray-300 border-hover'>
          <div className='card-body p-9'>
            <div className='d-flex justify-content-between align-items-center mb-4'>
              <div className='d-flex align-items-center'>
                <img
                  alt=''
                  width='55'
                  height='55'
                  className='rounded-circle w-55px me-3'
                  src={category?.image || '/media/icons/duotune/ecommerce/ecm002.svg'}
                />
                <a
                  href='#'
                  className='fs-3 fw-bolder text-gray-700 text-hover-primary'
                  onClick={() => setEditModalOpen(true)}
                >
                  {category?.name || name} {user_name && `(${user_name})`}
                  {brand && (
                    <p className='fs-6 m-0 fw-normal'>
                      {brand?.name} {model && `(${model})`}
                    </p>
                  )}
                </a>
              </div>

              {isArchive === false && track_package.length === 0 && items.length > 1 && (
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-3 svg-icon-gray-900'
                    />
                  </button>
                </div>
              )}
            </div>
            <p className='text-muted fw-semobold text-muted d-block fs-7'>
              <span className={`p-1 fs-8 fw-semobold  StatusBar__status bg-light me-3`}>
                <FormattedMessage
                  id={+used === 1 ? 'GENERAL.STATUSES.USED' : 'GENERAL.STATUSES.NEW'}
                />
              </span>
              {commercial === 1 && +track?.type_delive === 0 && (
                <span className={`badge badge-light-info fw-bolder px-2 py-2`}>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.COM_PROD' />
                </span>
              )}
              {/* {commercial === 1 && (
                <span className={`p-1 fs-7 fw-semobold  StatusBar__status bg-light`}>
                  <FormattedMessage id='RECEIVERS.STATUSES.INTERN_REC' />
                </span>
              )} */}
            </p>

            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>{qty}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_QTY' />
                </span>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${cost}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_COST' />
                </span>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${total}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_TOTAL' />
                </span>
              </div>

              {!!wgt && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>{wgt} кг</span>
                  <span className='fw-bold text-gray-400'>
                    <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_WEIGHT' />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
