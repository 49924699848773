/* eslint-disable react-hooks/exhaustive-deps */
import {FC, createContext, useContext, useEffect, useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {WithChildren} from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageCustomData {
  checkoutSum?: number | string
  debts?: number
  confirmRequest?: (id: number | string) => void
  confirmId?: number | string
  checkoutShow: boolean
  refetchFunction?: (data: any) => void
  refetchQuery?: string
  showPayAll?: boolean
  tosShow: boolean
}
export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  pageCustomData?: PageCustomData
  setPageCustomData: (_customData: any) => void
  notifications?: Array<NotType>
  addNotification: (data: NotAddType) => void
  removeNotification: (id: string) => void
}
export interface NotType {
  id: string
  type?: string
  header?: string
  body: string
  intlValues?: Record<string, number | string>
  status?: string
}
type Modify<T, R> = Omit<T, keyof R> & R
interface NotAddType
  extends Modify<
    NotType,
    {
      id?: string
    }
  > {}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageCustomData: (_customData: any) => {},
  addNotification: (_data: NotAddType) => {},
  removeNotification: (_id: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const {currentUser} = useAuth()
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [pageCustomData, setPageCustomData] = useState<PageCustomData>({
    checkoutShow: false,
    tosShow:
      currentUser && (currentUser?.tosConfirmed === false || currentUser?.tosConfirmed === null)
        ? true
        : false,
  })
  const [notifications, setNotifications] = useState<Array<NotType>>([])
  const addNotification = (data: NotAddType) => {
    const d = new Date()
    const id = data.id ? data.id : 'n_' + d.getDay() + d.getTime()
    setNotifications((prev) => [...prev, {...data, id}])
  }
  const removeNotification = (id: string) => {
    setNotifications((prev) => prev.filter((item) => item.id !== id))
  }

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageCustomData,
    setPageCustomData,
    notifications,
    addNotification,
    removeNotification,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
