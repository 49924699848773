/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {Languages} from '../../../../app/modules/auth/components/Languages'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'

export function HeaderWrapper() {
  const {config, classes, websiteData} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  const {logoDarkUrl, logoUrl, logoPrep, faviconUrl} = websiteData
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1 px-0 ',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        <div className='d-flex align-items-center justify-content-start flex-lg-grow-0 header-logo-minimized ps-10'>
          {/* <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'> */}
          {config.app.sidebar?.display && (
            <Link to='/profile'>
              {config.layoutType === 'dark-sidebar' ? (
                <img
                  alt='Logo'
                  id='dark-sidebar-logo'
                  src={logoDarkUrl ? `${logoPrep + logoDarkUrl}` : toAbsoluteUrl(``)}
                  className='h-45px app-sidebar-logo-default d-none d-md-block'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={logoDarkUrl ? `${logoPrep + logoDarkUrl}` : toAbsoluteUrl('')}
                    id='dark-logo'
                    className='h-25px app-sidebar-logo-default theme-light-show d-none d-md-block'
                  />
                  <img
                    alt='Logo'
                    src={logoUrl ? `${logoPrep + logoUrl}` : toAbsoluteUrl('')}
                    id='light-logo'
                    className='h-25px app-sidebar-logo-default theme-dark-show d-none d-md-block'
                  />
                </>
              )}

              <img
                alt='Logo'
                src={faviconUrl ? `${logoPrep + faviconUrl}` : toAbsoluteUrl('')}
                className='h-30px d-block d-md-none'
              />
            </Link>
          )}
          {/* </div> */}
        </div>{' '}
        {!config.app.sidebar?.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/profile'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={logoUrl ? `${logoPrep + logoUrl}` : toAbsoluteUrl(``)}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}{' '}
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px me-4'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTSVG
                  path='/media/company-specific/pokupka/asideMenuMobIcon.svg'
                  className=' svg-icon-1'
                />
              </div>
            </div>
          </>
        )}
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-center justify-content-between flex-lg-grow-1 px-6'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-center'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}

          <Navbar />
        </div>
      </div>
    </div>
  )
}
