/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage} from 'react-intl'
import {IOrder} from '../../../../core/_models'

type Props = {
  buyout: IOrder
}

const BuyoutItemsInfoCell = ({buyout}: Props) => {
  const {products} = buyout
  return (
    <div>
      {products.length > 4 ? (
        <>
          {products?.slice(0, 4).map(({category, quantity, priceForOne}, index) => (
            <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
              {!category?.name && !priceForOne
                ? ''
                : `${category?.name ? `${category?.name}` : '-'} x ${
                    quantity ? `${quantity}` : '-'
                  } x ${priceForOne ? `$${priceForOne}` : '-'}`}
            </span>
          ))}
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <FormattedMessage
              id='GENERAL.COUNTABLES.MORE.GOODS'
              values={{itemCount: products.length - 4}}
            />
          </span>
        </>
      ) : (
        products?.map(({category, quantity, priceForOne}, index) => (
          <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
            {!category?.name && !priceForOne
              ? ''
              : `${category?.name ? `${category?.name}` : '-'} x ${
                  quantity ? `${quantity}` : '-'
                } x ${priceForOne ? `$${priceForOne}` : '-'}`}
          </span>
        ))
      )}
    </div>
  )
}

export {BuyoutItemsInfoCell}
