import clsx from 'clsx'
import React from 'react'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

const SidebarHeader = () => {
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  return (
    <div
      className='app-sidebar-logo app-sidebar-header justify-content-start'
      id='kt_app_sidebar_logo'
    >
      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px rotate me-4',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG
            path='/media/company-specific/pokupka/asideMenuIcon.svg'
            className='svg-icon-2x rotate-180'
            svgClassName='app-sidebar-toggle-icon'
          />
        </div>
      )}{' '}
      <button className='rounded-circle btn text-white fw-normal fw-bold btn-normal px-5 ms-2 btn-custom-text'>
        <KTSVG path='/media/company-specific/pokupka/icons/plus.svg' />
        <span className='menu__link-text'>
          <FormattedMessage id='ASIDE.CREATE' />
        </span>
      </button>
    </div>
  )
}

export default SidebarHeader
