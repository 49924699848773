import {useFormikContext} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {ICrauzerProfileForm} from '../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useAuth} from '../../../../modules/auth'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import ProfileContacts from './contacts/ProfileContacts'
import {ProfileEmail} from './contacts/ProfileEmail'
import {ProfileNumberItem} from './contacts/ProfileNumberItem'

type TProps = {
  displayClass?: string
}
const ProfileBasics = ({displayClass = ''}: TProps) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<Partial<ICrauzerProfileForm>>()
  const {currentUser} = useAuth()

  const [citiesLoading, setCitiesLoading] = useState(false)
  const [citiesResults, setCitiesResults] = useState<IBasicSelect[]>([])

  const filterCities = async (query: string) => {
    setCitiesLoading(true)
    await locationsApi
      .getAddressCities({city: query, vector: false})
      .then((res) => {
        setCitiesResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setCitiesLoading(false))
  }
  return (
    <div className={`profile-basics-container ${displayClass}`}>
      <InputTemplate
        title={intl.formatMessage({id: 'PROFILE.PAGE.OVERVIEW.NAME_LABEL'})}
        inputName='firstName'
        required={false}
        type='text'
        placeholder={intl.formatMessage({
          id: 'PROFILE.PAGE.OVERVIEW.NAME_PLACEHOLDER',
        })}
      />
      <InputTemplate
        title={intl.formatMessage({id: 'PROFILE.PAGE.OVERVIEW.LAST_NAME_LABEL'})}
        inputName='lastName'
        required={false}
        type='text'
        placeholder={intl.formatMessage({
          id: 'PROFILE.PAGE.OVERVIEW.LAST_NAME_PLACEHOLDER',
        })}
      />
      <InputTemplate
        title={intl.formatMessage({id: 'PROFILE.PAGE.OVERVIEW.PATRONYMIC_LABEL'})}
        inputName='patronymic'
        required={false}
        type='text'
        placeholder={intl.formatMessage({
          id: 'PROFILE.PAGE.OVERVIEW.PATRONYMIC_PLACEHOLDER',
        })}
      />
      <div className='mb-4'>
        <ProfileEmail user={currentUser} />
      </div>

      <ProfileContacts />

      <InputTemplate
        title={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
        inputName='password'
        required={false}
        type='text'
        placeholder={'**********'}
        addSymbol={<KTSVG path='/media/company-specific/pokupka/eye.svg' className='svg-icon-3' />}
      />
      <InputTemplate
        title={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
        inputName='passwordConf'
        required={false}
        type='text'
        placeholder={'**********'}
        addSymbol={<KTSVG path='/media/company-specific/pokupka/eye.svg' className='svg-icon-3' />}
      />
      {/* <InputTemplate
        inputName='language'
        type='select'
        value={values.language}
        title={'Оберіть мову'}
        reactSelectprops={{
          options: citiesResults,
          isLoading: citiesLoading,
          onInputChange: filterCities,
          isClearable: true,
          selectName: 'language',
          setFieldValue: setFieldValue,
          placeholder: 'Мова',
          currentValue: citiesResults.find((item: any) => item.value === values.city),
        }}
      />{' '} */}
    </div>
  )
}

export default ProfileBasics
