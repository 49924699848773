import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {reformTrackApiProductsToForm} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {usePageData} from '../../../../../_metronic/layout/core'
import {useListView} from '../../core/ListViewProvider'
import {ITrackFormProds, Track} from '../../core/_models/_tracks-models'
import {getTrackById} from '../../core/_requests'
import {CreateTrackOnePageForm} from './CreateTrackOnePageForm'

export const OnePageFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const [goodsFields, setGoodsFields] = useState<ITrackFormProds[] | null>(null)

  const {
    isLoading,
    data: track,
    error,
  } = useQuery(
    'trackEdit',
    () => {
      return getTrackById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  const getGoodsFields = async (track: Track) => {
    // const fields = await reformTrackApiProductsToForm(track.prods)
    // setGoodsFields(fields)
  }

  useEffect(() => {
    if (track) {
      getGoodsFields(track)
    }
  }, [track])
  if (isLoading) {
    return <div>Loading...</div>
  }
  if (itemIdForUpdate === null) {
    return <CreateTrackOnePageForm />
  }

  if (!error && track && goodsFields) {
    return <CreateTrackOnePageForm track={track} goodsFields={[]} />
  }

  return null
}
