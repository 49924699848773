import {useFormikContext} from 'formik'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {ICrauzerProfileForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {useLayout} from '../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../../modules/auth'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ProfileNumberItem} from './ProfileNumberItem'

const ProfileNumber = () => {
  const intl = useIntl()
  const {websiteData} = useLayout()
  const {currentUser} = useAuth()
  const {values} = useFormikContext<Partial<ICrauzerProfileForm>>()
  return (
    <>
      <InputTemplate
        title={intl.formatMessage({id: 'PROFILE.PAGE.CONTACTS.NUMBER'})}
        inputName='phoneNumber'
        type='tel'
        value={values.phoneNumber || ''}
        custom={
          <div className='d-flex w-100 align-items-center'>
            <ProfileNumberItem user={currentUser} />{' '}
            {currentUser?.phoneNumber &&
              (currentUser?.phoneNumberConfirmed === false ||
                (!currentUser.phoneNumberConfirmed &&
                  currentUser.phoneNumberTelegramConfirmed === false)) && (
                <OverlayTrigger
                  placement='left'
                  overlay={<Tooltip>Підтвердити через Телеграм-бот.</Tooltip>}
                >
                  <a
                    target={'_blank'}
                    href={`https://t.me/${websiteData.telegramBotName}`}
                    rel='noreferrer noopener'
                    className='btn btn-icon btn-light btn-lg ms-2'
                  >
                    <KTSVG path='/media/svg/brand-logos/telegram-app.svg' className='svg-icon-1' />
                  </a>
                </OverlayTrigger>
              )}
          </div>
        }
      />

      {currentUser?.phoneNumber && currentUser?.phoneNumberTelegramConfirmed === false && (
        <div>
          <span
            className={`p-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(
              false
            )}`}
          >
            Номер не підтверджено
          </span>
        </div>
      )}
    </>
  )
}

export default ProfileNumber
