const QUERIES = {
  ARCHIVED_BUYOUTS_LIST: 'archived-buyouts-list',
  ARCHIVED_TRACKS_LIST: 'archived-tracks-list',
  BALANCE_CURRENT: 'balance-current',
  BALANCE_LIST: 'balance-list',
  BUYOUTS_LIST: 'buyouts-list',
  COUNTRY_ABBR_LIST: 'countries-abbr-list',
  DASHBOARD_INFO: 'dashboard-info',
  DIRECTIONS_LIST: 'directions-list',
  FULF_REC_ADDRESS_LIST: 'fulfReceiverAddresses',
  FULF_RECEIVERS_LIST: 'fulf-receivers-list',
  PARCELS_LIST: 'parcels-list',
  PAYMENT_OPTIONS: 'payment-options',
  PRODUCTS_LIST: 'fulfillment-products-list',
  REC_ADDRESS_LIST: 'receiverAddresses',
  RECEIVERS_LIST: 'receivers-list',
  TRACKS_LIST: 'tracks-list',
  USERS_LIST: 'users-list',
  WAREHOUSES_LIST: 'warehouseAddresses',
}

const LS_KEYS = {
  PAGINATION_KEY: 'tablePagination',
  BALANCE_SHOW_ALL_KEY: 'balanceShowAll',
}

const TABLE_LIST_FILTER_KEY = (listName: string) => {
  return `${listName}-filter`
}

const FULF_RECEIVER_VERIFICATION_STATUS = {
  VERIFIED: 'Verified', // success
  CHECKED: 'Checked', // danger
  WAITING: 'Waiting', // warning
}

const GTM_SCRIPTS = (id: string) => {
  return {
    dateTime: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`,

    iframe: `<iframe src='https://www.googletagmanager.com/ns.html?id=${id}'height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
  }
}

const CARDFOOTER_NOTIFICATIONS = (msg?: string) => {
  return {
    SUCCESSFULLY_SAVED: {
      success: true,
      color: 'success',
      intl: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
      icon: '/media/icons/duotune/general/gen043.svg',
    },
    ERROR: {
      success: true,
      color: 'danger',
      intl: msg || '',
      icon: '/media/icons/duotune/general/gen044.svg',
    },
  }
}
export {
  QUERIES,
  LS_KEYS,
  GTM_SCRIPTS,
  FULF_RECEIVER_VERIFICATION_STATUS,
  TABLE_LIST_FILTER_KEY,
  CARDFOOTER_NOTIFICATIONS,
}
