import {useRef, useEffect} from 'react'
import {Field, useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  inputName: string
  value: number
  placeholder?: string
  backgroundClass?: string
  autoFocus?: boolean
  inputFontSize?: string
  handleChange?: any
  disabled?: boolean
}

export function NumberWithControls({
  inputName,
  placeholder,
  backgroundClass,
  autoFocus,
  inputFontSize,
  handleChange,
  disabled = false,
  value,
}: Props) {
  const autoFocusRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus === true) {
      autoFocusRef.current?.focus()
    }
  }, [autoFocus])
  const {setFieldValue, validateField, setFieldTouched} = useFormikContext()

  return (
    <div
      className='position-relative'
      //   id='kt_modal_finance_setup'
      //   data-kt-dialer='true'
      //   data-kt-dialer-min='50'
      //   data-kt-dialer-max='50000'
      //   data-kt-dialer-step='100'
      //   data-kt-dialer-prefix='$'
      //   data-kt-dialer-decimals='2'
    >
      <button
        type='button'
        className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0'
        // data-kt-dialer-control='decrease'
        onClick={() => {
          const newValue = value - 1
          setFieldValue(inputName, newValue)
          if (handleChange) {
            handleChange(newValue)
          }
        }}
        disabled={value === 1}
      >
        <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon-1' />
      </button>
      {/* <input
          type='text'
          className='form-control form-control-solid border-0 ps-12'
          data-kt-dialer-control='input'
          placeholder='Amount'
          name='finance_setup'
          readOnly={true}
          value='$50'
        /> */}
      <Field
        type='number'
        disabled={disabled}
        className={`form-control form-control-lg text-center form-control-solid text-dark fs-5 input_general input-border ${
          inputFontSize ? inputFontSize : ''
        } ${backgroundClass ? backgroundClass : ''}`}
        name={inputName}
        placeholder={placeholder ? placeholder : ''}
        innerRef={autoFocusRef}
        id={inputName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(inputName, e.target.value)
          if (handleChange) {
            handleChange(+e.target.value)
          }
        }}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => (e.target.placeholder = '')}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          validateField(inputName)
          setFieldTouched(inputName, true)
          e.target.placeholder = `${placeholder ? placeholder : ''}`
        }}
      />
      <button
        type='button'
        className='btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0'
        // data-kt-dialer-control='increase'
        onClick={() => {
          const newValue = value + 1
          setFieldValue(inputName, newValue)
          if (handleChange) {
            handleChange(newValue)
          }
        }}
      >
        <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-1' />
      </button>
    </div>
  )
}
