/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import SocialLinks from '../../../../app/modules/custom/pokupka/social-links/SocialLinks'
import {ReportDrawer} from '../../../partials/layout/report-drawer/ReportDrawer'
import {ToggleReportDrawer} from '../../../partials/layout/report-drawer/ToggleReportDrawer'
import {ILayout, useLayout} from '../../core'
import {SidebarFooter} from '../sidebar/SidebarFooter'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='d-md-none w-100 d-flex justify-content-around border-bottom border-1'>
        <SocialLinks />
      </div>
      <div className='col-3 d-none d-xl-block'></div>
      <div className='w-100 d-flex justify-content-start justify-content-md-center me-5'>
        <div className='w-100 d-flex flex-column flex-md-row justify-content-left justify-content-md-around justify-content-xl-center mb-5 mb-md-0'>
          <a
            href='#'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-link text-custom-grey-light text-start text-md-center text-decoration-md-underline footer-link me-4'
          >
            <FormattedMessage id='FOOTER.WEBSITE' />{' '}
          </a>
          <a
            href='#'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-link text-custom-grey-light text-start text-md-center text-decoration-md-underline footer-link me-4'
          >
            <FormattedMessage id='FOOTER.TERMS' />{' '}
          </a>
          <a
            href='#'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-link text-custom-grey-light text-start text-md-center text-decoration-md-underline footer-link'
          >
            <FormattedMessage id='FOOTER.CONTACTS' />
          </a>
        </div>
      </div>

      <div className='w-100 text-custom-grey-light order-2 order-md-1'>
        <span className='fw-semibold me-1'>&copy;1989 - {new Date().getFullYear().toString()}</span>
        <a
          href='my.pokupka.eu'
          target='_blank'
          className='text-custom-grey-light text-hover-primary'
        >
          my.pokupka.eu.{' '}
        </a>
        All rights reserved.
      </div>
      {/* <div className='my-5 w-100'>

        <ToggleReportDrawer withTooltip={false} />

      </div> */}

      {/* <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a href='https://keenthemes.com/' target='_blank' className='menu-link px-2'>
            About
          </a>
        </li>

        <li className='menu-item'>
          <a href='https://devs.keenthemes.com/' target='_blank' className='menu-link px-2'>
            Support
          </a>
        </li>

        <li className='menu-item'>
          <a
            href='https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469'
            target='_blank'
            className='menu-link px-2'
          >
            Purchase
          </a>
        </li>
      </ul> */}
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
