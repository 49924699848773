import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../i18n/Metronici18n'
import {Direction, DirectionsQueryResponse} from '../tsHelpers/directionsHelpers'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API = process.env.REACT_APP_API_PATH

const ENDPOINT_BASE = `/directions`

const fetchDirectionsWithIds = () => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${API}/public${ENDPOINT_BASE}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<Direction[]>) => {
      return d.data
    })
}

export const directionsApi = {
  fetchDirectionsWithIds,
}
