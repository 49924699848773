import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

const SocialLinks = () => {
  return (
    <>
      <a
        href='#'
        target='_blank'
        rel='noopener noreferrer'
        className='btn btn-sm btn-icon btn-icon-muted'
      >
        <KTSVG
          path={'/media/company-specific/pokupka/socials/facebook-sidebar.svg'}
          className='svg-icon-2x'
        />
      </a>
      <a
        href='#'
        target='_blank'
        rel='noopener noreferrer'
        className='btn btn-sm btn-icon btn-icon-muted'
      >
        <KTSVG
          path={'/media/company-specific/pokupka/socials/instagram-sidebar.svg'}
          className='svg-icon-2x'
        />
      </a>
      <a
        href='#'
        target='_blank'
        rel='noopener noreferrer'
        className='btn btn-sm btn-icon btn-icon-muted'
      >
        <KTSVG
          path={'/media/company-specific/pokupka/socials/youtube-sidebar.svg'}
          className='svg-icon-2x'
        />
      </a>
    </>
  )
}

export default SocialLinks
