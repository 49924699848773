import {FormattedMessage} from 'react-intl'

type TProps = {id: string}

const PokupkaPageTitle = ({id}: TProps) => {
  return (
    <div className='d-block d-md-none p-5'>
      <h1 className='fw-normal fs-4 mb-0 text-custom-grey'>
        <FormattedMessage id={id} />{' '}
      </h1>
    </div>
  )
}

export default PokupkaPageTitle
