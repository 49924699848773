import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {CategoriesQueryResponse, ICategory} from '../tsHelpers/categoriesHelpers'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const BASE_URL = `${API_URL}/product_categories`

const getCategories = (query: string): Promise<CategoriesQueryResponse> => {
  return axios.get(`${BASE_URL}?${query}`).then((d: AxiosResponse<CategoriesQueryResponse>) => {
    console.log(d)
    const transformed = {...d.data, data: d.data.data?.reverse()}
    return transformed
  })
}

const getCategoryById = (id: ID): Promise<ICategory | undefined> => {
  return axios.get(`${BASE_URL}/${id}`).then((response: AxiosResponse<ICategory>) => response.data)
}

export {getCategories, getCategoryById}
