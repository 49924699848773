import ProfileContactNumbers from './ProfileContactNumbers'
import ProfileNumber from './ProfileNumber'

const ProfileContacts = () => {
  return (
    <>
      <div className='mb-4'>
        <ProfileNumber />
      </div>
      <ProfileContactNumbers />
    </>
  )
}

export default ProfileContacts
