/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG} from '../../../helpers'

export const HeaderUserCreds = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [copied, setCopied] = useState(false)
  const target = useRef<HTMLParagraphElement | null>(null)
  const value = currentUser?.externalId
    ? `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}, ${currentUser?.externalId}`
    : ''

  return (
    <>
      {(currentUser?.firstName || currentUser?.lastName || currentUser?.externalId) && (
        <div>
          <div className='d-flex flex-column'>
            {/* <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName} {currentUser?.lastName}
            </div> */}
            <div className='d-flex flex-gutter align-items-center text-custom-additional'>
              <span className='fw-bold fs-5'>ID:</span>
              <CopyToClipboard
                text={value}
                onCopy={() => {
                  setCopied(true)
                }}
              >
                <span
                  ref={target}
                  onClick={() => {
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }, 1000)
                  }}
                  className='text-custom-additional bg-light-dark text-hover-primary fs-5 px-2'
                >
                  {currentUser?.externalId}
                </span>
              </CopyToClipboard>{' '}
              <Overlay target={target.current} show={copied} placement='bottom'>
                {(props) => (
                  <Tooltip id='overlay-example' {...props}>
                    {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
