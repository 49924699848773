import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

import {IStore} from '../../../../../_metronic/helpers/custom/tsHelpers/storeModels'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {AddBuyout, IEditBuyout, IOrder, OrdersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const TRACKS_URL = `/tracking_numbers`
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${TRACKS_URL}`

const getBuyouts = (query: string): Promise<OrdersQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<OrdersQueryResponse>) => d.data)
}

const getBuyoutById = async (id: ID): Promise<IOrder | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const deleteBuyout = async (id: ID) => {
  const {selectedLang} = getConfig()

  const res = await axios.get(`${BASE_URL}/delete`, {
    params: {id},
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return res
}

const addBuyout = (buyout: Partial<AddBuyout>): Promise<IOrder | undefined> => {
  return axios
    .post(`${BASE_URL}/create-as-company`, buyout)
    .then((response: AxiosResponse<Response<IOrder>>) => response.data)
    .then((response: Response<IOrder>) => response.data)
}

const editBuyout = (buyout: IEditBuyout): Promise<IOrder | undefined> => {
  return axios
    .post(`${BASE_URL}/edit`, buyout)
    .then((response: AxiosResponse<Response<IOrder>>) => response.data)
    .then((response: Response<IOrder>) => response.data)
}

// external, move later to a resp folder
const STORES_URL = `/website_shops`
const ST_BASE_URL = `${API_URL}${STORES_URL}`

const getStoreByProductUrl = async (url: string): Promise<IStore | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${ST_BASE_URL}/find_by_product_url?productUrl=${url}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}
export {getBuyouts, deleteBuyout, addBuyout, editBuyout, getBuyoutById, getStoreByProductUrl}
