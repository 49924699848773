import {ScrollComponent} from '../../../../assets/ts/components'
import {SidebarMenuMain} from './SidebarMenuMain'

const SidebarMenu = () => {
  const scrollElement = document.querySelector<HTMLElement>('.app-sidebar-wrapper')

  let height
  if (scrollElement) {
    const scrollComponent = new ScrollComponent(scrollElement, {saveState: true})

    height = scrollComponent.getHeight()
  }

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div id='kt_app_sidebar_menu_wrapper' className={`app-sidebar-wrapper my-5 h-${height}`}>
        <div
          className='menu menu-column menu-sub-indention'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          <SidebarMenuMain />
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
