import {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {IDashboardItemRow} from './core/_models'
import {DashboardItemStatBase} from './DashboardItemStatBase'

interface Props {
  index: number
  row: IDashboardItemRow
  rowsLength: number
  type?: string
}

export function DashboardItemStat({row, index, rowsLength, type}: Props) {
  const date = row.date?.split(' ')[0].split('-').reverse().join('-')
  const finalDateAndTime = date ? date + ' ' + row.date?.split(' ')[1] : ''
  return (
    <Fragment key={`lw26-rows-${index}`}>
      {/* {row.link ? (
        <Link to={`/${row.link}`} state={{filters: row.filters}}>
          <DashboardItemStatBase
            row={row}
            key={index}
            index={index}
            rowsLength={rowsLength}
            type={type}
          />
        </Link>
      ) : ( */}
      <DashboardItemStatBase
        row={row}
        key={index}
        index={index}
        rowsLength={rowsLength}
        type={type}
      />
      {/* )} */}
    </Fragment>
  )
}
