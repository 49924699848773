/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {ICategory} from '../../../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackItemsInfoCell = ({track}: Props) => {
  const {prods} = track
  return (
    <div>
      <Dimensions track={track} />
      <TrackProds track={track} />
    </div>
  )
}

export {TrackItemsInfoCell}

const Dimensions = ({track}: Props) => {
  const {height, width, length, weight} = track
  const intl = useIntl()
  return (
    <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
      {!weight && !height && !length && !width
        ? ''
        : `${weight ? `${weight} ${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}` : '-'}, ${
            height ? `${height}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
          } x ${width ? `${width}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'} x ${
            length ? `${length}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
          }`}
    </p>
  )
}

const TrackProds = ({track}: Props) => {
  const {prods} = track
  const [showAllGoods, setShowAllGoods] = useState(false)

  return (
    <>
      {prods.length > 2 && !showAllGoods ? (
        <>
          {prods?.slice(0, 2).map(({category, qty, cost, name}, index) => (
            <TrackProdsItem
              name={name}
              qty={+qty}
              category={category}
              cost={+cost}
              index={index}
              key={index}
            />
          ))}
          <a
            className='text-muted fw-normal text-muted d-block fs-7 cursor-pointer text-hover-primary'
            onClick={() => setShowAllGoods(true)}
          >
            ...
          </a>
        </>
      ) : (
        <>
          {prods?.map(({category, qty, cost, name}, index) => (
            <TrackProdsItem
              name={name}
              qty={+qty}
              category={category}
              cost={+cost}
              index={index}
              key={index}
            />
          ))}

          {showAllGoods && (
            <a
              className='text-muted fw-normal text-muted d-block fs-7 cursor-pointer text-hover-primary'
              onClick={() => setShowAllGoods(false)}
            >
              Сховати
            </a>
          )}
        </>
      )}
    </>
  )
}

type TProps = {
  name: string
  qty: number
  category: ICategory
  cost: number
  index: number
}
const TrackProdsItem = ({name, qty, category, cost, index}: TProps) => {
  return (
    <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
      {!category?.name && !name && !cost
        ? ''
        : `${category?.name ? `${category?.name}` : `${name}`}, ${qty ? `${qty}` : '-'} x ${
            cost ? `$${cost}` : '-'
          }`}
    </span>
  )
}
