/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {setLanguage, useLang} from '../../../../_metronic/i18n/Metronici18n'
import {Dropdown} from 'react-bootstrap'

const languages = [
  // {
  //   lang: 'en',
  //   name: 'English',
  //   flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  // },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
  {
    lang: 'uk',
    name: 'Українська',
    flag: toAbsoluteUrl('/media/flags/ukraine.svg'),
  },
]

type TProps = {chosenLangStyle?: string; arrowStyle?: string}

const Languages = ({chosenLangStyle, arrowStyle}: TProps) => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <Dropdown className='menu-state-bg menu-state-primary'>
      <Dropdown.Toggle
        variant='transparent'
        className={`text-uppercase ${chosenLangStyle ? chosenLangStyle : 'fs-2'} fw-bolder`}
        style={{color: '#045487'}}
        size='sm'
        id='dropdown-lang'
      >
        {currentLanguage?.lang}{' '}
        <img
          className={`rounded-1 ms-2 ${arrowStyle ? arrowStyle : 'w-15px h-15px '}`}
          src={toAbsoluteUrl('/media/company-specific/pokupka/arrow-down.svg')}
          alt='metronic'
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className='menu-sub menu-sub-dropdown w-175px py-4 fw-bold'>
        {languages.map((l) => (
          <Dropdown.Item className='menu-item px-3' key={l.lang} as='div'>
            <a
              href='#'
              className={clsx('menu-link d-flex px-5 text-uppercase', {
                active: l.lang === currentLanguage?.lang,
              })}
              onClick={() => {
                setLanguage(l.lang)
              }}
            >
              <span className='symbol symbol-20px me-4 text-uppercase'>
                {/* <img
                  className='bg-transparent'
                  src={toAbsoluteUrl('/media/company-specific/pokupka/arrow.svg')}
                  alt='metronic'
                /> */}
              </span>
              {l.lang}
            </a>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {Languages}
