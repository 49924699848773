import {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../_metronic/helpers'

import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {crauzerProfileApi} from '../../core/_requests'

interface Props {
  user: ICrauzerUser | undefined
  setFooterNotification?: any
}

export function ProfileEmail({user, setFooterNotification}: Props) {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)

  const resendEmail = async () => {
    try {
      setLoading(true)
      await crauzerProfileApi.confirmEmailResend()

      setFooterNotification({
        ...CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED,
        intl: 'GENERAL.TOOLTIPS.SUCCESSFUL_SENT_EMAIL',
      })

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error) {
      console.log('resend err', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='d-flex w-100 align-items-center'>
        <InputTemplate
          inputName='email'
          type='mail'
          title={intl.formatMessage({id: 'PROFILE.EMAIL_L'})}
          // fieldWidth='w-50'
          placeholder={intl.formatMessage({id: 'PROFILE.EMAIL.PLACEHOLDER'})}
        />
        {user?.emailConfirmed === false && (
          <OverlayTrigger
            placement='left'
            overlay={<Tooltip>Повторно відправити лист для підтвердження мейлу.</Tooltip>}
          >
            <button
              type='button'
              className='btn btn-icon btn-light btn-lg ms-2'
              onClick={resendEmail}
              disabled={loading}
            >
              <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-1' />
            </button>
          </OverlayTrigger>
        )}
      </div>
      {user?.emailConfirmed === false && (
        <div>
          {' '}
          <span
            className={`p-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(
              false
            )}`}
          >
            Пошту не підтверджено
          </span>
        </div>
      )}
    </div>
  )
}
