/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {useMutation} from 'react-query'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {createBuyoutSchema} from '../core/yup/schemas'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {addBuyout} from '../core/_requests'
import {CreateLoading} from '../../../../modules/custom/responseHandlers/CreateLoading'
import {CreateError} from '../../../../modules/custom/responseHandlers/CreateError'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../modules/auth'
import {useListView} from '../core/ListViewProvider'
import {createChatFromNewBuyoutComment} from '../../../../modules/chat/_requests'
import {Goods} from '../../../../modules/custom/sections/goods/Goods'
import {AddBuyoutForm} from '../core/_models'
import {reformTrackFormProductsToApi} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {Direction} from '../../../../../_metronic/helpers/custom/tsHelpers/directionsHelpers'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

type TProps = {directions: (IBasicSelect & Partial<Direction>)[]}

const CreateBuyout = ({directions}: TProps) => {
  const listView = useListView()
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()

  const goodsFields = [
    {
      category: {label: '', value: ''},
      qty: 1,
      cost: '',
      total: '',
      link: '',
      sku: '',
      color: '',
      size: '',
    },
  ]

  const inits: AddBuyoutForm = {
    direction: directions[0],
    prods: goodsFields,
  }

  const [createResponse, setCreateResponse] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const createBuyoutMutation = useMutation(addBuyout, {
    onMutate: () => {
      setIsLoading(true)
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      // if (data?.description) {
      //   createChatFromNewBuyoutComment({
      //     object_id: data.id,
      //     type: 'buyout',
      //     message: data.description,
      //   })
      // }
      setItemIdForUpdate(undefined)
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (values: AddBuyoutForm, actions: FormikValues) => {
    console.log('values', values)

    const buyoutData = {
      products: reformTrackFormProductsToApi(values.prods),
    }

    console.log('buyoutDataToSend', buyoutData)

    createBuyoutMutation.mutate(buyoutData)

    actions.resetForm()
  }

  return (
    <div className='card-body card-scroll create-order-height px-10 py-6'>
      {createResponse === 'loading' && <CreateLoading />}
      {createResponse === 'error' && <CreateError />}
      {createResponse !== 'error' && createResponse !== 'loading' && (
        <Formik
          validationSchema={createBuyoutSchema(intl)}
          initialValues={inits}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values, setFieldValue, errors}) => (
            <Form className='form' noValidate id='kt_modal_create_buyout_form'>
              {/* {JSON.stringify(errors)} */}

              <Goods
                prods={values.prods}
                setFieldValue={setFieldValue}
                values={values}
                listView={listView}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export {CreateBuyout}
