import {QUERIES} from '../../../_metronic/helpers'

import {useQuery} from 'react-query'
import {directionsFuncs} from '../../../_metronic/helpers/custom/funcs/directions'
import FlagsDirectionItem from './FlagsDirectionItem'
import {directionsApi} from '../../../_metronic/helpers/custom/api/directionsApi'

interface Props {
  chosenDirections: number[]
  additionalClass?: string
  minified?: boolean
}

function FlagsDirectionBar({chosenDirections, additionalClass, minified = false}: Props) {
  const {data: generalDirections} = useQuery(
    [QUERIES.DIRECTIONS_LIST],
    directionsApi.fetchDirectionsWithIds
  )

  console.log('generalDirections', generalDirections)

  return (
    <ul
      className={`FlagsDirectionBar me-2 ${additionalClass || ''} ${
        minified === true ? 'px-0 mb-0' : ''
      }`}
    >
      {' '}
      {generalDirections &&
        directionsFuncs
          .parseAvailableDirections(generalDirections, chosenDirections)
          .map((item, index) => (
            <FlagsDirectionItem key={index} from={item.from} to={item.to} minified={minified} />
          ))}
    </ul>
  )
}

export {FlagsDirectionBar}
