import {useState} from 'react'
import {IWarehouseData} from './core/_requests'
import {WarehouseLine} from './WarehouseLine'

type TProps = {
  item: IWarehouseData
  index: number
  activeTabIndex: number
  title?: string
  value?: string
  copied: string[]
  setCopied: (arr: string[]) => void
}

export function WarehouseItem({
  item,
  index,
  activeTabIndex,
  title,
  value,
  copied,
  setCopied,
}: TProps) {
  return (
    <div
      className={`tab-pane fade ${
        index === activeTabIndex ? 'show active' : 'd-none'
      } warehouses-table ${
        item.addressHidden === false &&
        item.secondaryAddressHidden === false &&
        item.addressData.length > 0 &&
        item.secondaryAddressData.length > 0
          ? 'd-flex'
          : ''
      }`}
      id={`kt_tab_pane_${item.id}`}
      role='tabpanel'
    >
      {/* {item.addressHidden === false &&
      item.secondaryAddressHidden === false &&
      item.addressData.length > 0 &&
      item.secondaryAddressData.length > 0 ? (
        <div className='d-flex'>
          {!item.addressHidden && (
            <div>
              <h4 className={`mb-5 text-center w-100 ${item.addressName ? '' : 'opacity-0'}`}>
                {item.addressName || 'placeholder'}
              </h4>

              {item.countryConfig.locationAddressFields?.map((fieldName, index) => {
                const value = item.addressData[index]
                return (
                  <WarehouseLine
                    title={fieldName || ''}
                    value={value || ''}
                    key={`${item.id}-${index}-line`}
                    copied={copied}
                    setCopied={setCopied}
                  />
                )
              })}
            </div>
          )}

          {!item.secondaryAddressHidden && item.secondaryAddressData.length > 0 && (
            <div>
              <h4
                className={`mb-5 text-center w-100 ${item.secondaryAddressName ? '' : 'opacity-0'}`}
              >
                {item.secondaryAddressName || 'placeholder'}
              </h4>

              {item.countryConfig.locationAddressFields?.map((fieldName, index) => {
                const value = item.secondaryAddressData[index]
                return (
                  <WarehouseLine
                    title={fieldName || ''}
                    value={value || ''}
                    key={`${item.id}-${index}-line`}
                    copied={copied}
                    setCopied={setCopied}
                  />
                )
              })}
            </div>
          )}
        </div>
      ) : (
        <> */}
      {!item.addressHidden && (
        <div
          className={`${
            !item.secondaryAddressHidden && item.secondaryAddressData.length > 0
              ? 'col-lg-5'
              : 'col-7'
          }`}
        >
          <h4 className={`mb-5 text-center w-100 ${item.addressName ? '' : 'opacity-0'}`}>
            {item.addressName || 'placeholder'}
          </h4>

          {item.countryConfig.locationAddressFields?.map((fieldName, index) => {
            const value = item.addressData[index]
            return (
              <WarehouseLine
                title={fieldName || ''}
                value={value || ''}
                key={`${item.id}-${index}-line`}
                copied={copied}
                setCopied={setCopied}
              />
            )
          })}
        </div>
      )}

      {!item.secondaryAddressHidden && item.secondaryAddressData.length > 0 && (
        <div className={`${!item.addressHidden && item.addressData.length > 0 ? 'col-lg-5' : ''}`}>
          <h4 className={`mb-5 text-center w-100 ${item.secondaryAddressName ? '' : 'opacity-0'}`}>
            {item.secondaryAddressName || 'placeholder'}
          </h4>

          {item.countryConfig.locationAddressFields?.map((fieldName, index) => {
            const value = item.secondaryAddressData[index]
            return (
              <WarehouseLine
                title={fieldName || ''}
                value={value || ''}
                key={`${item.id}-${index}-line`}
                copied={copied}
                setCopied={setCopied}
              />
            )
          })}
        </div>
      )}
      {/* </>
      )} */}
    </div>
  )
}
